import { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { isBrowser } from 'react-device-detect'
import styled from '@emotion/styled'
import { slugify } from '@open-tender/utils'
import { getActiveElement } from 'utils/helpers'
import { MenuNavButton } from './MenuNavButton'

const MenuNavView = styled.div`
  position: sticky;
  top: ${(props) => props.theme.layout.navHeight};
  flex: 1 1 28rem;
  min-width: 20rem;
  max-width: 28rem;
  height: calc(100vh - ${(props) => props.theme.layout.navHeight});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${(props) => props.theme.layout.margin}
    ${(props) => props.theme.layout.padding};
  padding-right: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    top: ${(props) => props.theme.layout.navHeightMobile};
  }
`

const MenuNav = ({
  items,
  offset = 0,
}: {
  items: string[]
  offset?: number
}) => {
  const [active, setActive] = useState<Element | null>(null)
  const theme = useTheme()
  const { navHeight, navHeightMobile } = theme.layout
  const height = isBrowser ? navHeight : navHeightMobile
  const heightInPixels = parseInt(height.replace('rem', '')) * 10
  const topOffset = heightInPixels * 2 + 1
  const elements = Array.from(
    document.getElementsByClassName('section')
  ) as HTMLElement[]
  const navOffset = offset - topOffset

  useEffect(() => {
    const handleScroll = () => {
      if (elements.length) {
        setActive(getActiveElement(elements, -navOffset + 1))
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [navOffset, elements, active])

  return (
    <MenuNavView>
      {items.map((name, index) => {
        const sectionId = slugify(name)
        const activeId = active ? active.id : null
        return (
          <MenuNavButton
            key={`${sectionId}-${index}`}
            name={name}
            offset={navOffset}
            active={activeId === sectionId}
          />
        )
      })}
    </MenuNavView>
  )
}

export default MenuNav
