import { Header, HeaderLogo } from 'components'
import { BackToMenu, NavMenu } from 'components/buttons'

const HeaderCheckout = ({ maxWidth = '100%' }: { maxWidth?: string }) => {
  return (
    <Header
      maxWidth={maxWidth}
      title={<HeaderLogo />}
      left={<BackToMenu />}
      right={<NavMenu />}
    />
  )
}

export default HeaderCheckout
