import styled from '@emotion/styled'
import Body from 'components/Body'
import Heading from 'components/Heading'

// export const MenuHeaderOrderView = styled.button`
//   display: flex;
//   align-items: center;
//   gap: 0.4rem;
//   color: ${(props) => props.theme.buttons.colors.header};
//   margin: 0 0 0 1rem;
//   @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
//     margin: 0;
//   }

//   &:enabled:hover {
//     color: ${(props) => props.theme.buttons.colors.headerHover};
//     @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
//       color: ${(props) => props.theme.buttons.colors.header};
//     }
//   }
// `

// export const MenuHeaderContent = styled.span`
//   display: flex;
//   align-items: center;
//   @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
//     flex-direction: column;
//     align-items: flex-start;
//   }
// `

export const MenuHeaderOrderView = styled.div`
  margin: 0 0 0 1rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0;
  }
`

export const MenuHeaderContent = styled.span<{ showLogo: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  text-align: left;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const MenuHeaderOrderServiceType = styled.span`
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

export const MenuHeaderOrderRevenueCenter = styled.span<{ showLogo: boolean }>`
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    max-width: ${(props) => (props.showLogo ? '10rem' : '13rem')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const MenuHeaderOrderRequestedAt = styled.span`
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`

export const MenuHeaderPin = styled.span`
  display: block;
  margin: 0.1rem 0 0 0;
`

export const MenuHeaderEdit = styled.span`
  display: block;
  margin: 0.2rem 0 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`

export const MenuHeaderServiceType = styled(Body)`
  margin: 0 0.5rem 0 0;
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.buttons.colors.header};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }

  button:enabled:hover & {
    color: ${(props) => props.theme.buttons.colors.headerHover};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.buttons.colors.header};
    }
  }
`

export const MenuHeaderRevenueCenter = styled(Heading)`
  transition: ${(props) => props.theme.links.transition};
  color: ${(props) => props.theme.buttons.colors.header};
  text-align: left;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button:enabled:hover & {
    color: ${(props) => props.theme.buttons.colors.headerHover};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.buttons.colors.header};
    }
  }
`

export const MenuHeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: 0 -1rem 0 0;
    }
  }
`

export const MenuHeaderGroupOrder = styled.div`
  margin: 0 1rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 -1.5rem 0 0;
  }
`

export const MenuHeaderItem = styled.div`
  margin: 0 1rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 0 0 1rem;
  }
`
