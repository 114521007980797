import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { animateScroll as scroll } from 'react-scroll'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  LatLng,
  OrderType,
  RevenueCenter,
  ServiceType,
} from '@open-tender/types'
import { makeOrderTypeFromParam } from '@open-tender/utils'
import {
  selectBrand,
  selectGeoLatLng,
  selectOrder,
  setOrderServiceType,
  selectRevenueCenters,
  selectSettings,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  GoogleMap,
  GoogleMapsMarker,
  Header,
  HeaderLogo,
  Main,
  MapsAutocomplete,
  ScreenreaderTitle,
} from 'components'
import { Back, Cart, NavMenu } from 'components/buttons'
import RevenueCentersSelect from './RevenueCentersSelect'
import RevenueCenterMarkers from './RevenueCenterMarkers'

const checkLatLng = (prev: LatLng | null, next: LatLng | null) => {
  const { lat: prevLat, lng: prevLng } = prev || {}
  const { lat: nextLat, lng: nextLng } = next || {}
  if (next && nextLat !== prevLat && nextLng !== prevLng) {
    return next
  }
  return null
}

const RevenueCenters = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [activeMarker, setActiveMarker] = useState<number | null>(null)
  const { title: siteTitle, pilot_slug } = useAppSelector(selectBrand) || {}
  const { orderType, serviceType, address } = useAppSelector(selectOrder)
  const { googleMaps } = useAppSelector(selectSettings) || {}
  const {
    apiKey,
    defaultCenter = null,
    zoom = 14,
    styles,
    icons,
  } = googleMaps || {}
  const geoLatLng = useAppSelector(selectGeoLatLng)
  const addressCenter: LatLng | null = address
    ? { lat: address.lat, lng: address.lng }
    : null
  const currentCenter = addressCenter || geoLatLng || defaultCenter
  const [center, setCenter] = useState<LatLng | null>(currentCenter)
  const [userCenter, setUserCenter] = useState<LatLng | null>(currentCenter)
  const { revenueCenters } = useAppSelector(selectRevenueCenters)
  const hasTypes = orderType && serviceType
  const query = new URLSearchParams(useLocation().search)
  const param = query.get('type')
  const missingAddress = serviceType === 'DELIVERY' && !address ? true : false
  const backPath =
    orderType === 'CATERING' ? '/catering-address' : '/order-type'
  const updatedLatLng = checkLatLng(center, currentCenter)

  useEffect(() => {
    if (pilot_slug) {
      navigate(`/locations/${pilot_slug}`)
    }
  }, [pilot_slug, navigate])

  useEffect(() => {
    if (updatedLatLng) {
      setUserCenter(updatedLatLng)
      if (!activeMarker) setCenter(updatedLatLng)
    }
  }, [updatedLatLng, activeMarker])

  useEffect(() => {
    let paramOrderType = null
    if (param) {
      const order = makeOrderTypeFromParam(param) ?? []
      if (paramOrderType) {
        dispatch(
          setOrderServiceType({
            orderType: order[0] as OrderType,
            serviceType: order[1] as ServiceType,
            isOutpost: order[2] as boolean,
          })
        )
        if (paramOrderType[0] === 'CATERING') navigate('/catering-address')
      }
    }
    if (!hasTypes && !paramOrderType) navigate('/account')
  }, [hasTypes, param, dispatch, navigate])

  const setActive = useCallback((revenueCenter: RevenueCenter) => {
    if (revenueCenter) {
      const { revenue_center_id, address, slug } = revenueCenter
      setActiveMarker(revenue_center_id)
      setCenter({ lat: address.lat, lng: address.lng })
      const element = document.getElementById(slug)
      if (element) {
        const position = element.offsetTop
        scroll.scrollTo(position, {
          duration: 500,
          smooth: true,
          offset: 0,
        })
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Locations | {siteTitle}</title>
      </Helmet>
      <Content maxWidth="76.8rem">
        <Header
          maxWidth="76.8rem"
          borderColor="transparent"
          style={{ boxShadow: 'none' }}
          title={<HeaderLogo />}
          left={<Back label="Back to Order Type Selection" path={backPath} />}
          right={
            <>
              <Cart />
              <NavMenu />
            </>
          }
        />
        <Main>
          <ScreenreaderTitle>Locations</ScreenreaderTitle>
          {apiKey && styles && center && userCenter ? (
            <GoogleMap
              apiKey={apiKey}
              zoom={zoom}
              styles={styles}
              center={center}
              loader={<ClipLoader size={30} loading={true} />}
            >
              <MapsAutocomplete setCenter={setCenter} />
              <RevenueCentersSelect />
              <RevenueCenterMarkers
                icons={icons}
                missingAddress={missingAddress}
                activeMarker={activeMarker}
                setActive={setActive}
                revenueCenters={revenueCenters}
              />
              <GoogleMapsMarker
                title="Your Location"
                position={{
                  lat: userCenter.lat,
                  lng: userCenter.lng,
                }}
                icon={icons?.user.url}
                size={icons?.user.size}
                anchor={icons?.user.anchor}
              />
            </GoogleMap>
          ) : (
            <></>
          )}
        </Main>
      </Content>
    </>
  )
}

export default RevenueCenters
