import { useState, useEffect, useMemo } from 'react'
import {
  CheckoutErrorParams,
  CheckoutTenderGiftCard,
  Money,
} from '@open-tender/types'
import {
  handleCheckoutError,
  makeCheckError,
  makeNumeric,
} from '@open-tender/utils'
import {
  selectCheckout,
  updateForm,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, FormError, Input } from 'components'
import {
  CheckoutValutecCardButton,
  CheckoutValutecCardInputs,
  CheckoutValutecCardView,
} from './CheckoutValutec.styled'

const makeErrMsg = (checkErrors?: CheckoutErrorParams) => {
  if (!checkErrors) return null
  const checkoutErrors = makeCheckError(checkErrors)
  const errors = handleCheckoutError(checkoutErrors)
  if (!errors.tenders) return null
  const tenderErrors = Object.values(errors.tenders) as string[]
  const giftCardError = tenderErrors.find(
    (i) => i.includes('gift') || i.toLowerCase().includes('valutec')
  )
  return giftCardError || null
}

const CheckoutValutecCard = ({
  applyGiftCard,
  isPaid,
}: {
  applyGiftCard: (
    cardNumber: string,
    pin: string,
    balance: Money | null
  ) => void
  isPaid: boolean
}) => {
  const dispatch = useAppDispatch()
  const [cardNumber, setCardNumber] = useState('')
  const [cardPin, setCardPin] = useState('')
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const { loading, check, form } = useAppSelector(selectCheckout)
  const errMsg = makeErrMsg(check?.errors)
  const checkoutErrors = check?.errors ? makeCheckError(check?.errors) : null
  const errors = handleCheckoutError(checkoutErrors)
  const tenderError = errors.tenders
    ? (Object.values(errors.tenders)[0] as string)
    : null
  const isSumError = tenderError?.includes('sum')
  const disabled = submitting || !cardNumber || !cardPin

  const applied = useMemo<CheckoutTenderGiftCard | null>(
    () =>
      check?.tenders.find(
        (i) => 'card_number' in i && i.card_number === cardNumber
      ) as CheckoutTenderGiftCard | null,
    [check?.tenders, cardNumber]
  )

  const isApplied = !!applied || isSumError
  const { balance, card_number } = applied || {}
  const isLoading = loading === 'pending'
  const isOtherTender = isPaid && !isLoading

  const formApplied = useMemo<CheckoutTenderGiftCard | null>(
    () =>
      form?.tenders.find(
        (i) => 'card_number' in i && i.card_number === cardNumber
      ) as CheckoutTenderGiftCard | null,
    [form?.tenders, cardNumber]
  )

  const appliedBalance = formApplied?.balance || null
  const isFormApplied = !!formApplied

  useEffect(() => {
    if (loading === 'idle' && submitting) {
      setSubmitting(false)
      if (errMsg) setError(errMsg)
      // if error, remove gift card tender
      if (isFormApplied && !isApplied) {
        const tenders = form.tenders.filter(
          (i) => 'card_number' in i && i.card_number !== cardNumber
        )
        dispatch(updateForm({ tenders }))
      }
      setCardNumber('')
      setCardPin('')
    }
  }, [
    dispatch,
    loading,
    submitting,
    errMsg,
    isFormApplied,
    isApplied,
    cardNumber,
    form.tenders,
  ])

  useEffect(() => {
    if (
      balance &&
      appliedBalance &&
      parseFloat(balance) < parseFloat(appliedBalance)
    ) {
      const tenders = form.tenders.map((i) => {
        return 'card_number' in i && i.card_number === card_number
          ? { ...i, amount: balance, balance }
          : i
      })
      dispatch(updateForm({ tenders }))
    }
  }, [dispatch, balance, appliedBalance, form.tenders, card_number])

  const handleCardNumber = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setCardNumber(makeNumeric(evt.target.value))
  }

  const handlePin = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setError('')
    setCardPin(makeNumeric(evt.target.value))
  }

  const apply = () => {
    setError('')
    setSubmitting(true)
    applyGiftCard(cardNumber, cardPin, null)
  }

  return (
    <>
      <CheckoutValutecCardView>
        <CheckoutValutecCardInputs>
          <Input
            type="number"
            name="card_number"
            label="Card Number"
            value={cardNumber}
            onChange={handleCardNumber}
            disabled={isOtherTender}
            pattern="[0-9]*"
            style={{ margin: 0 }}
          />
          <Input
            type="number"
            name="pin"
            label="PIN"
            value={cardPin}
            onChange={handlePin}
            disabled={isOtherTender}
            pattern="[0-9]*"
            style={{ margin: 0 }}
          />
        </CheckoutValutecCardInputs>
        <CheckoutValutecCardButton>
          <Button
            onClick={apply}
            disabled={disabled}
            size="small"
            color="secondary"
          >
            {submitting ? 'Applying...' : 'Apply'}
          </Button>
        </CheckoutValutecCardButton>
      </CheckoutValutecCardView>
      <FormError errMsg={error} />
    </>
  )
}

export default CheckoutValutecCard
