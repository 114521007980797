import styled from '@emotion/styled'
import { isBrowser } from 'react-device-detect'
import { ThemeOverlay } from '@open-tender/types'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { Tag } from '.'
import { AlertCircle, Slash } from './icons'

const MenuItemOverlayView = styled.div<{ color: keyof ThemeOverlay }>`
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: ${(props) => props.theme.border.radius};
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: ${(props) => props.theme.overlay[props.color]};
`

const MenuItemOverlay = ({
  isSoldOut,
  allergenAlert,
}: {
  isSoldOut?: boolean
  allergenAlert?: string
}) => {
  const { soldOutMessage } = useAppSelector(selectContentSection('menu')) || {}
  const soldOutMsg = isBrowser
    ? soldOutMessage || 'Sold out for day'
    : 'Sold out'
  const color: keyof ThemeOverlay = isSoldOut ? 'dark' : 'alert'

  const itemTag = isSoldOut ? (
    <Tag
      icon={<Slash strokeWidth={2} size={14} />}
      text={soldOutMsg}
      color="alert"
    />
  ) : allergenAlert ? (
    <Tag
      icon={<AlertCircle strokeWidth={2} size={14} />}
      text={allergenAlert}
      color="alert"
    />
  ) : null

  return itemTag ? (
    <MenuItemOverlayView color={color}>{itemTag}</MenuItemOverlayView>
  ) : null
}

export default MenuItemOverlay
