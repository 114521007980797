import {
  Cart,
  CartItem,
  CartItemOption,
  CheckoutCheck,
  ConfigBrand,
  Customer,
  MenuCategory,
  MenuItems,
  OrderItem,
} from '@open-tender/types'
import { makeOrderItem } from '@open-tender/utils'
import { Middleware } from '@reduxjs/toolkit'
import { isMobile, isTablet } from 'react-device-detect'
import { sha256 } from 'utils/helpers'
import { loadState } from './localStorage'
import { AppState } from '@open-tender/cloud'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export const sendPageView = ({
  customer,
  brand,
}: {
  customer: Customer | null
  brand: ConfigBrand | null
}) => {
  const event = {
    event: 'page_view',
    page_url: window.location.href,
    page_path: window.location.pathname,
    page_title: document.title,
    user_id: customer?.customer_id,
    user_login_status: customer ? 'logged_in' : 'logged_out',
    device_type: isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop',
    device_os: navigator.userAgent,
    brand_id: brand?.brandId,
    account_id: customer?.customer_id,
    account_name: `${customer?.first_name} ${customer?.last_name}`,
  }
  sendEventToGTM(event)
}

export const sendMenuView = ({
  items,
  category,
}: {
  items: MenuItems
  category: Partial<MenuCategory>
}) => {
  const event = {
    event: 'view_item_list',
    item_list_id: category.id,
    item_list_name: category.name,
    items: items.map((item: any) => {
      const cartItem = makeOrderItem(item)
      return makeProduct(
        {
          ...cartItem,
          category_id: item.category_id,
          category_name: item.category_name,
        } as any,
        category
      )
    }),
  }
  sendEventToGTM(event)
}

export const sendMenuScroll = () => {
  const event = {
    event: 'scroll',
    scroll_depth: window.scrollY,
    scroll_percentage:
      (window.scrollY /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight)) *
      100,
  }
  sendEventToGTM(event)
}

export const sendItemView = (item: CartItem, category: MenuCategory | null) => {
  const event = {
    event: 'view_item',
    value: item.totalPrice,
    items: [makeProduct(item, category ?? undefined)],
  }
  sendEventToGTM(event)
}

export const sendCartView = (cart: Cart) => {
  const totalPrice = cart.reduce(
    (t, i) => (i.totalPrice ? t + i.totalPrice : 0),
    0
  )
  const event = {
    event: 'view_cart',
    value: totalPrice,
    items: cart.map((i) => makeProduct(i)),
  }
  sendEventToGTM(event)
}

export const sendCheckoutView = (check: CheckoutCheck) => {
  const totalPrice = check.cart.reduce(
    (t, i) => (i.price_total ? t + parseFloat(i.price_total) : 0),
    0
  )
  const event = {
    event: 'begin_checkout',
    value: totalPrice,
    items: check.cart.map((item) => makeProduct(item)),
    customer: check.customer,
  }
  sendEventToGTM(event)
}
export const sendAddPaymentInfo = () => {
  const event = {
    event: 'add_payment_info',
    currency: 'USD',
    payment_type: 'Credit Card',
  }
  sendEventToGTM(event)
}

const makeProduct = (
  item: CartItem | OrderItem | CartItemOption,
  category?: Partial<MenuCategory>
) => {
  const { id, name, quantity } = item

  const totalPrice =
    (item as CartItem).totalPrice || parseFloat((item as OrderItem).price_total)
  const selectedGroups = item.groups.map((group) => {
    return {
      name: group.name,
      id: group.id,
      options: (group as any).options
        .filter((o: CartItemOption) => o.quantity > 0)
        .map((op: CartItemOption) => ({
          name: op.name,
          id: op.id,
          quantity: op.quantity,
          price: op.price,
        })),
    }
  })
  const data = {
    item_id: id.toString(),
    item_name: name,
    item_list_id: (item as any).category_id ?? category?.id,
    item_list_name: (item as any).category_name ?? category?.name,
    quantity,
    groups: selectedGroups,
  }
  const price = totalPrice ? (totalPrice / quantity).toFixed(2) : null
  return price ? { ...data, price } : data
}

const makeProducts = (cart: Cart) => {
  return cart.map((item) => makeProduct(item))
}

const incrementProduct = (item: CartItem) => {
  return {
    ...makeProduct(item),
    quantity: item.increment,
  }
}

const makeGtmEvent = async ({
  type,
  payload,
  meta,
}: {
  type: string
  payload: any
  meta: any
}) => {
  switch (type) {
    case 'order/setOrderServiceType': {
      return {
        event: 'order_type_selected',
        ...payload,
      }
    }
    case 'order/setRevenueCenter': {
      return {
        event: 'location_selected',
        location_name: payload?.name,
        location_id: payload?.revenue_center_id,
      }
    }

    case 'order/addItemToCart': {
      const item: CartItem = payload
      return {
        event: 'add_to_cart',
        currency: 'USD',
        value: payload.totalPrice,
        items: [makeProduct(item)],
      }
    }
    case 'order/incrementItemInCart': {
      return {
        event: 'add_to_cart',
        currency: 'USD',
        value: payload.totalPrice,
        items: [incrementProduct(payload)],
      }
    }
    case 'order/removeItemToCart': {
      return {
        event: 'remove_from_cart',
        currency: 'USD',
        items: [makeProduct(payload)],
      }
    }
    case 'order/decrementItemInCart': {
      return {
        event: 'remove_from_cart',
        currency: 'USD',
        items: [incrementProduct(payload)],
      }
    }
    case 'confirmation/setConfirmationOrder': {
      const { order_id, totals, cart, delivery, customer } = payload
      if (!totals) return null
      const { tax, total } = totals
      return {
        event: 'purchase',
        transaction_id: order_id.toString(),
        value: total,
        tax,
        totals: totals,
        currency: 'USD',
        shippment: delivery,
        items: makeProducts(cart),
        customer,
      }
    }
    case 'guest/fetchGuest/rejected': {
      return {
        event: 'guest_email',
        email_id: await sha256(payload.email),
      }
    }
    case 'signUp/signUpCustomer/fulfilled': {
      return {
        event: 'sign_up',
        email_id: await sha256(meta?.arg?.data?.email),
      }
    }
    default:
      return null
  }
}

const sendEventToGTM = (event: any) => {
  if (!event) return
  const reduxState: AppState = loadState()
  const eventWithSessionId = {
    ...event,
    session_id: window.localStorage.getItem('session_id'),
    user_id: reduxState.customer.account.profile?.customer_id,
    timestamp: new Date().toISOString(),
    order_type: reduxState.order.orderType
      ? `${reduxState.order.orderType} - ${reduxState.order.serviceType}`
      : '',
    location_id:
      event.location_id ?? reduxState.order.revenueCenter?.revenue_center_id,
    location_name: event.location_name ?? reduxState.order.revenueCenter?.name,
    brand_id: process.env.REACT_APP_BRAND_ID,
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(eventWithSessionId)
  window.dataLayer.push(function (this: any) {
    this.reset()
  })
}

export const analytics: Middleware = (s) => (next) => async (action) => {
  const event = await makeGtmEvent(action)
  if (!event) return next(action)
  sendEventToGTM(event)
  return next(action)
}
