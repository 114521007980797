import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Auth, ConfigBrand } from '@open-tender/types'
import { ButtonLink } from 'components'
import { selectBrand, useAppSelector } from '@open-tender/cloud'

const ConfirmationLinksView = styled.div`
  margin: 1.5rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 1.5rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

interface ConfirmationLinksProps {
  auth: Auth | null
  brand: ConfigBrand | null
}

const ConfirmationLinks = ({ auth, brand }: ConfirmationLinksProps) => {
  const { pilot_slug } = useAppSelector(selectBrand) || {}
  const navigate = useNavigate()
  const newOrderRoute = pilot_slug ? `/locations/${pilot_slug}` : '/account'

  return (
    <ConfirmationLinksView>
      <p>
        {auth ? (
          <ButtonLink onClick={() => navigate('/account')}>
            Head back to your account
          </ButtonLink>
        ) : brand?.url ? (
          <a href={brand.url} rel="noopener noreferrer">
            Head back to our website
          </a>
        ) : (
          <ButtonLink onClick={() => navigate('/guest')}>
            Head back to our website
          </ButtonLink>
        )}
        <span> or </span>
        <ButtonLink onClick={() => navigate(newOrderRoute)}>
          start another order
        </ButtonLink>
      </p>
    </ConfirmationLinksView>
  )
}

export default ConfirmationLinks
