import { useTheme } from '@emotion/react'
import { CartItemGroup, CartItemOption, CartLevels } from '@open-tender/types'
import { formatQuantity, useModifier } from '@open-tender/utils'
import {
  selectDisplaySettings,
  selectSelectedAllergenNames,
  useAppSelector,
} from '@open-tender/cloud'
import { X } from 'components/icons'
import { Checkmark, Count, MenuItemTagsAllergens } from 'components'
import MenuItemOptionSimpleOverlay from './MenuItemOptionSimpleOverlay'
import {
  MenuItemOptionSimpleButton,
  MenuItemOptionSimpleColories,
  MenuItemOptionSimpleCount,
  MenuItemOptionSimpleDescription,
  MenuItemOptionSimpleInfo,
  MenuItemOptionSimpleName,
  MenuItemOptionSimplePrice,
  MenuItemOptionSimpleRemove,
  MenuItemOptionSimpleRemoveIcon,
  MenuItemOptionSimpleSoldOut,
  MenuItemOptionSimpleText,
  MenuItemOptionSimpleView,
  MenuItemOptionSimpleImage,
} from './MenuItemOptionSimple.styled'
import { isMobile } from 'react-device-detect'
import MenuItemOptionSimpleGroups from './MenuItemOptionSimpleGroups'

const MenuItemOptionSimple = ({
  group,
  option,
  levels = [],
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
}: {
  group: CartItemGroup
  option: CartItemOption
  levels: CartLevels
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  toggleOption: (levels: CartLevels) => void
  setOptionQuantity: (levels: CartLevels, quantity: number) => void
}) => {
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const modifier = useModifier(
    group,
    option,
    levels,
    toggleOption,
    incrementOption,
    decrementOption,
    setOptionQuantity,
    allergenAlerts,
    displaySettings
  )
  const {
    name,
    description,
    isSoldOut,
    price,
    cals,
    tags,
    allergens,
    optionGroups,
    showImage,
    showDescription,
    allergenAlert,
    bgStyle,
    isRadio,
    showGroups,
    toggle,
    increment,
    decrement,
    decrementDisabled,
    updatedLevels,
    groupAtMax,
    oneOnly,
  } = modifier
  const disabled =
    isSoldOut || (group.max !== 1 && groupAtMax && option.quantity === 0)
  const count = formatQuantity(option.quantity.toString())
  const { color, bgColor } = useTheme().success
  const classSelected = option.quantity > 0 ? 'selected' : ''
  const classHover = isMobile || disabled ? '' : ' show-hover'
  const className = classSelected + classHover
  const missingGroups = optionGroups
    ? optionGroups.filter((i) => i.quantity < i.min).length > 0
    : false

  const onClick = () => {
    if (isRadio || (group.max === 1 && option.quantity === 0)) {
      toggle()
    } else if ((group.max === 1 || option.max === 1) && option.quantity === 1) {
      decrement()
    } else {
      increment()
    }
  }

  return (
    <MenuItemOptionSimpleView showGroups={showGroups}>
      {option.quantity > 0 && (
        <>
          {missingGroups ? (
            <></>
          ) : isRadio || oneOnly ? (
            <MenuItemOptionSimpleCount>
              <Checkmark />
            </MenuItemOptionSimpleCount>
          ) : (
            <>
              <MenuItemOptionSimpleCount>
                <Count count={count} color={color} bgColor={bgColor} />
              </MenuItemOptionSimpleCount>
              <MenuItemOptionSimpleRemove
                onClick={decrement}
                disabled={decrementDisabled}
              >
                <MenuItemOptionSimpleRemoveIcon>
                  <X size={14} strokeWidth={2} />
                </MenuItemOptionSimpleRemoveIcon>
              </MenuItemOptionSimpleRemove>
            </>
          )}
        </>
      )}
      <MenuItemOptionSimpleButton
        onClick={onClick}
        disabled={disabled}
        showGroups={showGroups}
        className={className}
      >
        {showImage && (
          <MenuItemOptionSimpleImage style={bgStyle}>
            {allergenAlert ? <MenuItemOptionSimpleOverlay /> : null}
          </MenuItemOptionSimpleImage>
        )}
        <MenuItemOptionSimpleText>
          <MenuItemOptionSimpleInfo>
            {isSoldOut && (
              <MenuItemOptionSimpleSoldOut size="xSmall">
                Out of stock
              </MenuItemOptionSimpleSoldOut>
            )}
            <MenuItemOptionSimpleName size="xSmall">
              {name}
            </MenuItemOptionSimpleName>
            {showDescription && description ? (
              <MenuItemOptionSimpleDescription as="p">
                {description}
              </MenuItemOptionSimpleDescription>
            ) : null}
            {cals && (
              <MenuItemOptionSimpleColories>
                {cals} cals
              </MenuItemOptionSimpleColories>
            )}
            <MenuItemTagsAllergens
              tags={tags}
              allergens={allergens}
              style={{ margin: 0 }}
            />
          </MenuItemOptionSimpleInfo>
          {price !== null && (
            <MenuItemOptionSimplePrice>{price}</MenuItemOptionSimplePrice>
          )}
        </MenuItemOptionSimpleText>
      </MenuItemOptionSimpleButton>
      {optionGroups && showGroups ? (
        <MenuItemOptionSimpleGroups
          groups={option.groups}
          levels={updatedLevels}
          toggleOption={toggleOption}
          incrementOption={incrementOption}
          decrementOption={decrementOption}
          setOptionQuantity={setOptionQuantity}
          isChild={true}
        />
      ) : null}
    </MenuItemOptionSimpleView>
  )
}

export default MenuItemOptionSimple
