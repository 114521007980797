import styled from '@emotion/styled'
import Body from 'components/Body'

export const CheckoutValutecCardView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 0;
`

export const CheckoutValutecCardInputs = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 2rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  // input:disabled {
  //   opacity: 0.25;
  // }
`

export const CheckoutValutecCardButton = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

export const CheckoutValutecCardApplied = styled.div`
  margin: 2rem 0 0;
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const CheckoutValutecCardMessage = styled(Body)`
  color: ${(props) => props.theme.colors.success};
`

export const CheckoutValutecCardAppliedOther = styled(
  CheckoutValutecCardApplied
)`
  // color: ${(props) => props.theme.colors.alert};
  gap: 0.5rem;
`

export const CheckoutValutecCardOther = styled(Body)`
  // color: ${(props) => props.theme.colors.alert};
`
