import { Tag } from '@open-tender/types'

import {
  selectTags,
  setSelectedTags,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonSubmit,
  FormError,
  FormInputsView,
  FormSubmit,
  Loading,
  Switch,
} from 'components'
import { capitalize } from '@open-tender/utils'
import { useEffect, useState } from 'react'

const TagsForm = ({ callback }: { callback?: () => void }) => {
  const dispatch = useAppDispatch()
  const {
    entities: tags,
    selectedTags,
    loading,
    error,
  } = useAppSelector(selectTags)
  const [localSelectedTags, setLocalSelectedTags] = useState<number[]>([])

  const isLoading = loading === 'pending'

  useEffect(() => {
    setLocalSelectedTags(selectedTags.map((tag) => tag.tag_id))
  }, [selectedTags])

  const onTagSwitch = (tag: Tag) => {
    setLocalSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tag.tag_id)) {
        return prevSelectedTags.filter((id) => id !== tag.tag_id)
      } else {
        return [...prevSelectedTags, tag.tag_id]
      }
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newSelectedTags = tags.filter((tag) =>
      localSelectedTags.includes(tag.tag_id)
    )
    dispatch(setSelectedTags(newSelectedTags))
    callback?.()
  }

  return tags.length > 0 ? (
    <form id="tags-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={error?.detail} style={{ margin: '0 0 2rem' }} />
      <FormInputsView>
        {tags.map((tag) => (
          <Switch
            key={tag.tag_id}
            type="switch"
            label={capitalize(tag.name)}
            name={`${tag.tag_id}`}
            on={localSelectedTags.includes(tag.tag_id)}
            onChange={() => onTagSwitch(tag)}
          />
        ))}
      </FormInputsView>
      <FormSubmit>
        <ButtonSubmit>Submit Updates</ButtonSubmit>
      </FormSubmit>
    </form>
  ) : isLoading ? (
    <Loading text="Retrieving tags..." />
  ) : (
    <p>This brand {"doesn't"} currently have any tags configured</p>
  )
}

export default TagsForm
