import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { displayPrice } from '@open-tender/utils'
import {
  closeGroupOrder,
  resetCheckout,
  resetOrder,
  selectCanOrder,
  selectCartQuantity,
  selectCartTotal,
  selectCustomer,
  selectGroupOrder,
  selectMenuSlug,
  selectOrder,
  selectOrderLimits,
  setCart,
  toggleSidebar,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink, Button, Cart, Heading } from 'components'
import SidebarClose from './SidebarClose'
import {
  SidebarBack,
  SidebarButtons,
  SidebarCart,
  SidebarCartContainer,
  SidebarCheckout,
  SidebarFooter,
  SidebarHeader,
  SidebarHeaderTitle,
  SidebarView,
} from './Sidebar.styled'
import SidebarUpsell from './SidebarUpsell'

const Sidebar = React.forwardRef(
  (props, ref: React.LegacyRef<HTMLElement> | undefined) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { orderId, cart } = useAppSelector(selectOrder)
    const groupOrder = useAppSelector(selectGroupOrder)
    const { cartId, cartGuest, isCartOwner, spendingLimit } = groupOrder
    const cartCount = useAppSelector(selectCartQuantity)
    const cartTotal = useAppSelector(selectCartTotal)
    const menuSlug = useAppSelector(selectMenuSlug)
    const canOrder = useAppSelector(selectCanOrder)
    const { orderMinimum, orderMaximum } = useAppSelector(selectOrderLimits)
    const { auth } = useAppSelector(selectCustomer)
    const isMenu = pathname.includes('menu')
    const isCheckout = pathname.includes('checkout')
    const isReview = pathname.includes('review')
    const belowMinimum =
      isCartOwner || cartGuest
        ? false
        : orderMinimum && cartTotal < orderMinimum
    const aboveMaximum = isCartOwner
      ? false
      : orderMaximum && cartTotal > orderMaximum
    const notEmpty = cartCount !== 0 || (isCartOwner && isMenu)
    const canCheckout = canOrder && !belowMinimum && !aboveMaximum && notEmpty
    const showReview = cartGuest || (isMenu && isCartOwner)
    const orderMaxType =
      cartGuest && spendingLimit ? 'spending limit' : 'order maximum'
    const hasUpsell = true

    const back = () => {
      dispatch(toggleSidebar())
      if (!isMenu) navigate(menuSlug)
    }

    const review = () => {
      dispatch(toggleSidebar())
      if (!isReview) navigate('/review')
    }

    const checkout = () => {
      dispatch(toggleSidebar())
      if (!isCheckout) {
        navigate(
          hasUpsell ? '/add-ons' : auth ? '/checkout' : '/checkout/guest'
        )
      }
    }

    const reopen = () => {
      const customerCart = cart?.filter((i) => i.customerId)
      dispatch(setCart(customerCart))
      dispatch(toggleSidebar())
      dispatch(closeGroupOrder({ cartId: cartId ?? 0, closed: false })).then(
        () => {
          navigate('/review')
        }
      )
    }

    const cancelEdit = () => {
      dispatch(resetOrder())
      dispatch(resetCheckout())
      dispatch(toggleSidebar())
      navigate(`/account`)
    }

    return (
      <SidebarView ref={ref}>
        <div>
          <SidebarClose />
          <SidebarHeader>
            <SidebarHeaderTitle as="h2">
              {orderId ? `Editing Order ${orderId}` : 'Your Order'}
            </SidebarHeaderTitle>
            {!notEmpty ? (
              <p>Your cart is currently empty. Please add some items.</p>
            ) : (
              <p>
                <Heading>{cartCount} items</Heading> for a total of{' '}
                <Heading>${cartTotal.toFixed(2)}</Heading> before tax
              </p>
            )}
            {cartCount !== 0 && belowMinimum && (
              <div>
                <p>
                  Your cart total is below the order minimum of $
                  {displayPrice(`${orderMinimum}`)}. Please add some items.
                </p>
              </div>
            )}
            {aboveMaximum && (
              <div>
                <p>
                  Your cart total is above the {orderMaxType} of $
                  {displayPrice(`${orderMaximum}`)}. Please edit or remove one
                  or more items before submitting your order.
                </p>
              </div>
            )}
            {orderId ? (
              <p>
                <ButtonLink onClick={cancelEdit}>
                  Click here to cancel this edit.
                </ButtonLink>
              </p>
            ) : null}
            {isCartOwner && (
              <div>
                <p>
                  This view displays only the items you've added yourself. Click
                  the "Review All Orders" button to view items added by others.
                </p>
              </div>
            )}
          </SidebarHeader>
          <SidebarCart>
            <SidebarCartContainer>
              <Cart />
              <SidebarUpsell />
            </SidebarCartContainer>
          </SidebarCart>
          <SidebarFooter>
            <SidebarButtons>
              <SidebarBack>
                {isCheckout && cartId ? (
                  <Button onClick={reopen} size="big" color="secondary">
                    Reopen
                  </Button>
                ) : (
                  <Button
                    onClick={back}
                    size="big"
                    color="secondary"
                    disabled={!canOrder}
                    label={
                      !notEmpty
                        ? 'Your cart is currently empty. Please add some items.'
                        : undefined
                    }
                  >
                    Menu
                  </Button>
                )}
              </SidebarBack>
              <SidebarCheckout>
                {showReview ? (
                  <Button
                    onClick={review}
                    size="big"
                    color="primary"
                    disabled={!canCheckout}
                  >
                    {isReview
                      ? 'Close'
                      : isCartOwner
                      ? 'Review All Orders'
                      : 'Submit Order'}
                  </Button>
                ) : (
                  <Button
                    onClick={checkout}
                    size="big"
                    color="primary"
                    disabled={!canCheckout}
                  >
                    {isCheckout ? 'Close' : 'Checkout'}
                  </Button>
                )}
              </SidebarCheckout>
            </SidebarButtons>
          </SidebarFooter>
        </div>
      </SidebarView>
    )
  }
)

export default Sidebar
