import styled from '@emotion/styled'
import { ThemeFontSize } from '@open-tender/types'
import Preface from 'components/Preface'

const MenuItemTagsAllergensView = styled.div<{
  isCentered?: boolean
}>`
  display: flex;
  justify-content: ${(props) => (props.isCentered ? 'center' : 'flex-start')};
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0 0;
  column-gap: 1rem;

  .compact & {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      display: none;
    }
  }

  .centered & {
    justify-content: center;
  }

  span {
    display: block;
  }
`

const MenuItemTag = styled(Preface)<{ size: ThemeFontSize }>`
  font-size: ${(props) => props.theme.fonts.sizes[props.size]};
`

const MenuItemAllergen = styled(Preface)<{ size: ThemeFontSize }>`
  font-size: ${(props) => props.theme.fonts.sizes[props.size]};
  color: ${(props) => props.theme.colors.allergens};
`

const MenuItemTagsAllergens = ({
  tags,
  allergens,
  size = 'xxSmall',
  isCentered,
  style,
}: {
  tags?: string[]
  allergens?: string[]
  size?: ThemeFontSize
  isCentered?: boolean
  style?: React.CSSProperties
}) => {
  const hasTags = (tags?.length ?? 0) > 0
  const hasAllergens = (allergens?.length ?? 0) > 0

  if (!hasTags && !hasAllergens) return null

  return (
    <MenuItemTagsAllergensView isCentered={isCentered} style={style}>
      {hasTags
        ? tags?.map((tag) => (
            <MenuItemTag key={tag} size={size}>
              {tag}
            </MenuItemTag>
          ))
        : null}
      {hasAllergens
        ? allergens?.map((allergen) => (
            <MenuItemAllergen key={allergen} size={size}>
              {allergen}
            </MenuItemAllergen>
          ))
        : null}
    </MenuItemTagsAllergensView>
  )
}

export default MenuItemTagsAllergens
