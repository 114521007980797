import { useTheme } from '@emotion/react'
import { CartItemGroup, CartItemOption, CartLevels } from '@open-tender/types'
import { formatQuantity, useModifier } from '@open-tender/utils'
import {
  selectDisplaySettings,
  selectSelectedAllergenNames,
  useAppSelector,
} from '@open-tender/cloud'
import { X } from 'components/icons'
import {
  Checkmark,
  Collapsible,
  Count,
  MenuItemTagsAllergens,
} from 'components'
import MenuItemGroups from '../MenuItemGroups'
import MenuItemOptionCardOverlay from './MenuItemOptionCardOverlay'
import {
  MenuItemOptionCardButton,
  MenuItemOptionCardColories,
  MenuItemOptionCardCount,
  MenuItemOptionCardDescription,
  MenuItemOptionCardInfo,
  MenuItemOptionCardName,
  MenuItemOptionCardNested,
  MenuItemOptionCardPrice,
  MenuItemOptionCardRemove,
  MenuItemOptionCardRemoveIcon,
  MenuItemOptionCardSoldOut,
  MenuItemOptionCardText,
  MenuItemOptionCardView,
  MenuItemOptionCardImage,
} from './MenuItemOptionCard.styled'
import { isMobile } from 'react-device-detect'

const MenuItemOptionCard = ({
  group,
  option,
  levels = [],
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
}: {
  group: CartItemGroup
  option: CartItemOption
  levels: CartLevels
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  toggleOption: (levels: CartLevels) => void
  setOptionQuantity: (levels: CartLevels, quantity: number) => void
}) => {
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const {
    name,
    description,
    isSoldOut,
    price,
    cals,
    tags,
    allergens,
    optionGroups,
    showImage,
    showDescription,
    allergenAlert,
    bgStyle,
    isRadio,
    showGroups,
    toggle,
    increment,
    decrement,
    decrementDisabled,
    updatedLevels,
    groupAtMax,
    oneOnly,
  } = useModifier(
    group,
    option,
    levels,
    toggleOption,
    incrementOption,
    decrementOption,
    setOptionQuantity,
    allergenAlerts,
    displaySettings
  )
  const disabled =
    isSoldOut || (group.max !== 1 && groupAtMax && option.quantity === 0)
  const count = formatQuantity(option.quantity.toString())
  const { color, bgColor } = useTheme().success
  const classSelected = option.quantity > 0 ? 'selected' : ''
  const classHover = isMobile || disabled ? '' : ' show-hover'
  const className = classSelected + classHover

  const onClick = () => {
    if (isRadio || (group.max === 1 && option.quantity === 0)) {
      toggle()
    } else if ((group.max === 1 || option.max === 1) && option.quantity === 1) {
      decrement()
    } else {
      increment()
    }
  }

  return (
    <MenuItemOptionCardView showGroups={showGroups}>
      {option.quantity > 0 && (
        <>
          {isRadio || oneOnly ? (
            <MenuItemOptionCardCount>
              <Checkmark />
            </MenuItemOptionCardCount>
          ) : (
            <>
              <MenuItemOptionCardCount>
                <Count count={count} color={color} bgColor={bgColor} />
              </MenuItemOptionCardCount>
              <MenuItemOptionCardRemove
                onClick={decrement}
                disabled={decrementDisabled}
              >
                <MenuItemOptionCardRemoveIcon>
                  <X size={14} strokeWidth={2} />
                </MenuItemOptionCardRemoveIcon>
              </MenuItemOptionCardRemove>
            </>
          )}
        </>
      )}
      <MenuItemOptionCardButton
        onClick={onClick}
        disabled={disabled}
        showGroups={showGroups}
        className={className}
      >
        {showImage && (
          <MenuItemOptionCardImage style={bgStyle}>
            {allergenAlert ? <MenuItemOptionCardOverlay /> : null}
          </MenuItemOptionCardImage>
        )}
        <MenuItemOptionCardText>
          <MenuItemOptionCardInfo>
            {isSoldOut && (
              <MenuItemOptionCardSoldOut size="xSmall">
                Out of stock
              </MenuItemOptionCardSoldOut>
            )}
            <MenuItemOptionCardName size="xSmall">
              {name}
            </MenuItemOptionCardName>
            {showDescription && description ? (
              <MenuItemOptionCardDescription as="p">
                {description}
              </MenuItemOptionCardDescription>
            ) : null}
            {cals && (
              <MenuItemOptionCardColories>
                {cals} cals
              </MenuItemOptionCardColories>
            )}
            <MenuItemTagsAllergens
              tags={tags}
              allergens={allergens}
              style={{ margin: 0 }}
            />
          </MenuItemOptionCardInfo>
          {price !== null && (
            <MenuItemOptionCardPrice>{price}</MenuItemOptionCardPrice>
          )}
        </MenuItemOptionCardText>
      </MenuItemOptionCardButton>
      {optionGroups ? (
        <Collapsible show={showGroups}>
          <MenuItemOptionCardNested>
            <MenuItemGroups
              groups={option.groups}
              levels={updatedLevels}
              toggleOption={toggleOption}
              incrementOption={incrementOption}
              decrementOption={decrementOption}
              setOptionQuantity={setOptionQuantity}
              isChild={true}
            />
          </MenuItemOptionCardNested>
        </Collapsible>
      ) : null}
    </MenuItemOptionCardView>
  )
}

export default MenuItemOptionCard
