import { useEffect } from 'react'
import {
  fetchCustomerLoyalty,
  selectCustomerLoyaltyProgram,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Loading,
  LoyaltyPoints,
  LoyaltySpend,
  LoyaltyTiers,
  LoyaltyVisits,
} from 'components'

const AccountLoyaltyProgram = () => {
  const dispatch = useAppDispatch()
  const { program, loading } = useAppSelector(selectCustomerLoyaltyProgram)
  const { loyalty_type, perk, status, spend_name } = program || {}

  useEffect(() => {
    dispatch(fetchCustomerLoyalty())
  }, [dispatch])

  if (!program && loading === 'pending') {
    return <Loading text="Retrieving your loyalty status..." />
  }

  if (!program || !loyalty_type || !spend_name) return null

  return (
    <>
      {loyalty_type === 'POINTS' ? (
        <LoyaltyPoints program={program} perk={perk} to="/loyalty" />
      ) : loyalty_type === 'DOLLARS' ? (
        <LoyaltySpend program={program} perk={perk} to="/loyalty" />
      ) : loyalty_type === 'ORDERS' ? (
        <LoyaltyVisits program={program} perk={perk} to="/loyalty" />
      ) : null}
      {status && status.tiers && (
        <LoyaltyTiers
          loyaltyType={loyalty_type}
          status={status}
          tiers={status.tiers}
          spendName={spend_name}
        />
      )}
    </>
  )
}

export default AccountLoyaltyProgram
