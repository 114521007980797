import styled from '@emotion/styled'

export const PointsShopRewards = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
`

export const PointsShopRewardsContainer = styled.div`
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 -0.5rem;
  }
`

export const PointsShopRewardsReward = styled.div`
  width: 33.33333%;
  padding: 0 1rem 2.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 0 0.5rem 2.5rem;
  }
`

export const PointsShopBalanceView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 3rem auto;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} auto;
  }
`

export const PointsShopBalancePoints = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
