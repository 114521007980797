import styled from '@emotion/styled'

export const ProgressView = styled.div`
  position: relative;
  width: 100%;
  border-radius: 0.3rem;
  // background-color: rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.bgColors.tertiary};
  margin: 0 0 10rem;
`

export const ProgressBarFill = styled.div`
  width: 0;
  height: 0.6rem;
  border-radius: 0.3rem;
  animation: fill-bar 0.5s ease-in-out 0.5s forwards;
  background-color: ${(props) => props.theme.colors.primary};
`

interface ProgressPointProps {
  color?: string
  isFilled?: boolean
  isZero?: boolean
}

export const ProgressPoint = styled.div<ProgressPointProps>`
  position: absolute;
  top: -0.7rem;
  margin-left: ${(props) => (props.isZero ? '0' : '-1rem')};
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: ${(props) =>
    props.isFilled
      ? props.color
        ? `#${props.color}`
        : props.theme.colors.primary
      : props.theme.bgColors.tertiary};
`

export const ProgressPointContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const ProgressPointButton = styled.button`
  position: absolute;
  z-index: 1;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  text-indent: -5000px;

  &:hover ~ div {
    opacity: 1;
    visiblity: visible;
    transform: translateY(0);
  }
`

interface ProgressPointHoverProps {
  color?: string
  isLeft?: boolean
}

export const ProgressPointHover = styled.div<ProgressPointHoverProps>`
  position: absolute;
  bottom: 125%;
  left: ${(props) => (props.isLeft ? '0' : 'auto')};
  right: ${(props) => (props.isLeft ? 'auto' : '0')};
  width: 16rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: all 250ms ease;
  opacity: 0;
  visiblity: hidden;
  transform: translateY(1rem);
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }

  & > span {
    display: block;
    background-color: ${(props) => props.theme.bgColors.primary};
    margin: 0 auto;
    padding: 1rem 2rem;
    box-shadow: ${(props) => props.theme.boxShadow.outer};
    text-align: center;

    & > span {
      display: block;

      &:first-of-type {
        color: ${(props) => `#${props.color}`};
        font-size: ${(props) => props.theme.fonts.sizes.small};
        margin: 0 0 0.5rem;
      }

      &:last-of-type {
        font-size: ${(props) => props.theme.fonts.sizes.xSmall};
      }
    }
  }
`

export const ProgressAmount = styled.div<{ isZero?: boolean }>`
  position: absolute;
  top: 2.2rem;
  width: ${(props) => (props.isZero ? 'auto' : '10rem')};
  margin-left: ${(props) => (props.isZero ? '0' : '-5rem')};
  text-align: ${(props) => (props.isZero ? 'left' : 'center')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: cneter;
  gap: 0.5rem;

  span + span {
    text-align: center;
  }
`
