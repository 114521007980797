import styled from '@emotion/styled'
import { ThemeBackgroundColors } from '@open-tender/types'

const MainView = styled.main<{
  bgColor: keyof ThemeBackgroundColors
}>`
  label: Main;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.bgColors[props.bgColor]};
  padding-top: ${(props) => props.theme.layout.navHeight};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-top: ${(props) => props.theme.layout.navHeightMobile};
  }
`

const Main = ({
  bgColor = 'primary',
  style,
  children,
}: {
  bgColor?: keyof ThemeBackgroundColors
  style?: React.CSSProperties
  children: React.ReactNode
}) => {
  return (
    <MainView role="main" id="main" bgColor={bgColor} style={style}>
      {children}
    </MainView>
  )
}

export default Main
