/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useMemo } from 'react'
import { makeMapStyles } from '@open-tender/utils'
import { GoogleMapsStyles, LatLng } from '@open-tender/types'
import { useJsApiLoader } from '@react-google-maps/api'
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useJsApiLoader'

// https://github.com/laurencedorman/google-maps-api-loader
// https://codesandbox.io/s/lx947qjv0z

const eventsMapping: Record<
  string,
  { type: string; action: (map: google.maps.Map) => void }
> = {
  onCenterChanged: {
    type: 'center_changed',
    action: (map: google.maps.Map) => map.getCenter(),
  },
  onBoundsChangerd: {
    type: 'bounds_changed',
    action: (map: google.maps.Map) => map.getBounds(),
  },
}

interface MapsState {
  maps: typeof google.maps | null
  map: google.maps.Map | null
  sessionToken: google.maps.places.AutocompleteSessionToken | null
  autocomplete: google.maps.places.AutocompleteService | null
  loading: boolean
  error: any
}

const useGoogleMap = ({
  apiKey,
  zoom,
  styles,
  center,
  events = {},
}: {
  apiKey: string
  zoom: number
  styles: GoogleMapsStyles
  center: LatLng
  events?: any
}) => {
  const mapRef = useRef<HTMLDivElement>(null)
  const [mapState, setMapState] = useState<MapsState>({
    maps: null,
    map: null,
    sessionToken: null,
    autocomplete: null,
    loading: true,
    error: null,
  })

  const loadOptions = useMemo<UseLoadScriptOptions>(
    () => ({
      googleMapsApiKey: apiKey,
      libraries: ['places'],
    }),
    [apiKey]
  )

  const { isLoaded } = useJsApiLoader(loadOptions)

  useEffect(() => {
    if (!isLoaded || !mapRef.current) return
    const mapStyles = makeMapStyles(styles)
    const sessionToken = new google.maps.places.AutocompleteSessionToken()
    const autocomplete = new google.maps.places.AutocompleteService()
    const map = new google.maps.Map(mapRef.current as HTMLDivElement, {
      zoom,
      center,
      styles: mapStyles,
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      controlSize: 28,
    })
    Object.keys(events).forEach((eventName) =>
      map.addListener(eventsMapping[eventName].type, () =>
        events[eventName](eventsMapping[eventName].action(map))
      )
    )
    setMapState({
      maps: google.maps,
      map,
      sessionToken,
      autocomplete,
      loading: false,
      error: null,
    })
  }, [isLoaded])

  return { mapRef, ...mapState }
}

export default useGoogleMap
