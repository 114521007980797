import { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  selectAnnouncementsPage,
  selectBrand,
  selectContentSection,
  selectDisplaySettings,
  selectMenu,
  useAppSelector,
} from '@open-tender/cloud'
import { Content, Loading, Main, PageHero, ScreenreaderTitle } from 'components'
import { Back } from 'components/buttons'
import MenuHeader from './MenuHeader'
import MenuTop from './MenuTop'

const MenuAnnouncements = styled.div``

const MenuLoading = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

const MenuNew = () => {
  const heroRef = useRef<HTMLDivElement | null>(null)
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { loading } = useAppSelector(selectMenu) || {}
  const isLoading = loading === 'pending'
  const announcements = useAppSelector(selectAnnouncementsPage('MENU'))
  const { background, mobile, loadingMessage, videoUrl, videoUrlMobile } =
    useAppSelector(selectContentSection('menu')) || {}
  const { menuHero, menuHeroMobile } =
    useAppSelector(selectDisplaySettings) || {}
  const showHero = isMobile ? menuHeroMobile : menuHero
  const imageUrl = showHero ? (isMobile ? mobile : background) : null
  const videoUrlHero = showHero ? (isMobile ? videoUrlMobile : videoUrl) : null
  const { layout } = useTheme()
  const heroHeight = isMobile
    ? layout.menuHeroHeightMobile
    : layout.menuHeroHeight

  return (
    <>
      <Helmet>
        <title>Menu | {siteTitle}</title>
      </Helmet>
      <Content>
        <MenuHeader
          back={<Back label="Back to Locations" path="/locations" />}
        />
        <Main>
          <ScreenreaderTitle>Menu</ScreenreaderTitle>
          <MenuAnnouncements ref={heroRef}>
            <PageHero
              announcements={announcements}
              height={heroHeight}
              imageUrl={imageUrl}
              videoUrl={videoUrlHero}
            />
          </MenuAnnouncements>
          {isLoading ? (
            <MenuLoading>
              <Loading text={loadingMessage} />
            </MenuLoading>
          ) : (
            <>
              <MenuTop />
            </>
          )}
        </Main>
      </Content>
    </>
  )
}

export default MenuNew
