import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  selectBrand,
  selectContent,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { FormWrapper, ProfileForm } from 'components'
import {
  Content,
  DeleteAccount,
  HeaderUser,
  Main,
  PageContainer,
  PageTitle,
  VerifyAccount,
} from 'components'
import styled from '@emotion/styled'

const DeleteAccountStyled = styled(DeleteAccount)`
  margin-top: 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 6px;
  }
`

const AccountProfile = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { title: siteTitle, tpls } = useAppSelector(selectBrand) || {}
  const { profile: config } = useAppSelector(selectContent) || {}
  const { profile } = useAppSelector(selectCustomer)
  const { customer_id } = profile || {}

  useEffect(() => {
    if (!customer_id) return navigate('/account')
  }, [customer_id, dispatch, navigate])

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderUser />
        <Main>
          <PageContainer style={{ maxWidth: '72rem' }}>
            <PageTitle {...config}>
              {!tpls && <VerifyAccount style={{ margin: '2rem 0 0' }} />}
            </PageTitle>
            <FormWrapper>
              <ProfileForm />
              <DeleteAccountStyled />
            </FormWrapper>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default AccountProfile
