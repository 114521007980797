import styled from '@emotion/styled'

const RevenueCenterActionView = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0 0;
`

const RevenueCenterActionIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  line-height: 0;
  color: ${(props) => props.theme.fonts.body.color};
`

const RevenueCenterActionArrowText = styled.div`
  width: 100%;
  padding: 0 0.8rem;

  p {
    font-weight: 500;
    color: ${(props) => props.theme.colors.secondary};
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    ${(props) =>
      props.theme.cards.default.overrideFontColors
        ? `color: ${props.theme.cards.default.textColor}; font-size: ${props.theme.cards.default.textSize};`
        : ''};

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.xSmall};
      ${(props) =>
        props.theme.cards.default.overrideFontColors
          ? `font-size: ${props.theme.cards.default.textSizeMobile};`
          : ''};
    }
  }
`

interface RevenueCenterActionProps {
  icon: JSX.Element
  text: string | JSX.Element
  style?: React.CSSProperties
  arrow?: JSX.Element | null
}

const RevenueCenterAction = ({
  icon,
  text,
  style = undefined,
}: RevenueCenterActionProps) => {
  return (
    <RevenueCenterActionView style={style}>
      <RevenueCenterActionIcon>{icon}</RevenueCenterActionIcon>
      <RevenueCenterActionArrowText>
        <p>{text}</p>
      </RevenueCenterActionArrowText>
    </RevenueCenterActionView>
  )
}

export default RevenueCenterAction
