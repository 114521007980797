import { openModal } from '@open-tender/cloud'
import { Sliders } from '../icons'
import { useAppDispatch } from '@open-tender/cloud'
import { isMobileOnly } from 'react-device-detect'
import Button from 'components/Button'

const Allergens = ({ style }: { style?: React.CSSProperties }) => {
  const dispatch = useAppDispatch()

  return (
    <Button
      style={style}
      icon={<Sliders size={isMobileOnly ? 17 : 19} />}
      onClick={() => dispatch(openModal({ type: 'allergens' }))}
      color="header"
      size="header"
    >
      {!isMobileOnly && 'Allergens'}
    </Button>
  )
}

export default Allergens
