import { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { CartItem } from '@open-tender/types'
import { makeMenuItemLookup, makeUpsellItems } from '@open-tender/utils'
import {
  selectContentSection,
  selectMenu,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonLink, UpsellItems } from 'components'
import Body from 'components/Body'
import Heading from 'components/Heading'
import MenuItemUpsellOverlay from './MenuItemUpsellOverlay'

const MenuItemUpsellView = styled.div`
  position: fixed;
  z-index: 16;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.bgColors.primary};
  padding: ${(props) => props.theme.layout.padding} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile} 0;
  }
`

const MenuItemUpsellHeader = styled.div`
  text-align: center;
  padding: 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const MenuItemUpsellTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.big};
  }
`

const MenuItemUpsellSubtitle = styled(Body)`
  margin: 1rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const MenuItemUpsellDecline = styled.div`
  margin: 1.5rem 0 0;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 1rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

// const MenuItemUpsellContainer = styled.div`
//   label: MenuItemUpsellContainer;
//   display: flex;
//   justify-content: center;
// `

interface MenuItemUpsellProps {
  showUpsell?: boolean
  upsellItemIds: Array<number>
  cancel: () => void
}

const MenuItemUpsell = ({
  showUpsell,
  upsellItemIds,
  cancel,
}: MenuItemUpsellProps) => {
  const { categories } = useAppSelector(selectMenu)
  const upsells = useAppSelector(selectContentSection('upsells'))
  const { show, title, subtitle, decline } = upsells?.item || {}
  const itemLookup = useMemo(() => makeMenuItemLookup(categories), [categories])
  const upsellItems = makeUpsellItems(upsellItemIds, itemLookup)
  const skipUpsell = upsellItems.length === 0 || !show

  useEffect(() => {
    if (showUpsell && skipUpsell) cancel()
  }, [showUpsell, skipUpsell, cancel])

  if (skipUpsell) return null

  return (
    <>
      <MenuItemUpsellOverlay isOpen={showUpsell} cancel={cancel} />
      <TransitionGroup component={null}>
        {showUpsell && (
          <CSSTransition key="upsell" classNames="tray" timeout={250}>
            <MenuItemUpsellView>
              <MenuItemUpsellHeader>
                <MenuItemUpsellTitle as="div">{title}</MenuItemUpsellTitle>
                {subtitle && (
                  <MenuItemUpsellSubtitle as="div">
                    {subtitle}
                  </MenuItemUpsellSubtitle>
                )}
                <MenuItemUpsellDecline>
                  <ButtonLink onClick={cancel}>
                    {decline || 'No thanks'}
                  </ButtonLink>
                </MenuItemUpsellDecline>
              </MenuItemUpsellHeader>
              <UpsellItems
                upsellItems={upsellItems as CartItem[]}
                addCallback={cancel}
                isSmall={isMobile}
              />
            </MenuItemUpsellView>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

export default MenuItemUpsell
