import {
  openModal,
  selectOrder,
  selectTimezone,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { makeRequestedAtStr, serviceTypeNamesMap } from '@open-tender/utils'
import { MapPin } from 'components/icons'
import { isMobileOnly } from 'react-device-detect'
import {
  MenuHeaderContent,
  MenuHeaderOrderRequestedAt,
  MenuHeaderOrderRevenueCenter,
  MenuHeaderOrderServiceType,
  MenuHeaderOrderView,
} from './MenuHeader.styled'
import Button from 'components/Button'

const MenuHeaderOrder = ({
  cartGuest,
  showMenu,
  setShowMenu,
  showLogo,
}: {
  cartGuest: any
  showMenu: boolean
  setShowMenu: (show: boolean) => void
  showLogo: boolean
}) => {
  const dispatch = useAppDispatch()
  const tz = useAppSelector(selectTimezone)
  const { serviceType, orderType, revenueCenter, prepType, requestedAt } =
    useAppSelector(selectOrder)
  if (!serviceType || !orderType || !revenueCenter) return null
  const serviceTypeName =
    prepType === 'TAKE_OUT' ? 'Take Out' : serviceTypeNamesMap[serviceType]
  const prefix = orderType === 'CATERING' ? 'Catering ' : ''
  const requestedTime = tz ? makeRequestedAtStr(requestedAt, tz, true) : null

  // const toggle = (evt: React.MouseEvent<HTMLButtonElement>) => {
  //   evt.preventDefault()
  //   setShowMenu(!showMenu)
  // }

  const toggle = () => setShowMenu(!showMenu)
  const leave = () => dispatch(openModal({ type: 'groupOrderLeave' }))
  const onClick = cartGuest ? leave : toggle

  return (
    <MenuHeaderOrderView>
      <Button
        icon={<MapPin size={isMobileOnly ? 18 : 18} />}
        onClick={onClick}
        disabled={!!cartGuest}
        color="header"
        size="header"
      >
        <MenuHeaderContent showLogo={showLogo}>
          <MenuHeaderOrderServiceType>
            {prefix} {serviceTypeName} from{' '}
          </MenuHeaderOrderServiceType>
          <MenuHeaderOrderRevenueCenter showLogo={showLogo}>
            {revenueCenter.name}
          </MenuHeaderOrderRevenueCenter>
          <MenuHeaderOrderRequestedAt>
            - {requestedTime}
          </MenuHeaderOrderRequestedAt>
        </MenuHeaderContent>
      </Button>
    </MenuHeaderOrderView>
  )

  // return (
  //   <MenuHeaderOrderView onClick={onClick} disabled={!!cartGuest}>
  //     <MenuHeaderPin>
  //       <MapPin size={isMobile ? 18 : 18} />
  //     </MenuHeaderPin>
  //     <MenuHeaderContent>
  //       <MenuHeaderServiceType>
  //         {prefix}
  //         {serviceTypeName} from{' '}
  //       </MenuHeaderServiceType>
  //       <MenuHeaderRevenueCenter>{revenueCenter.name}</MenuHeaderRevenueCenter>
  //     </MenuHeaderContent>
  //     <MenuHeaderEdit>
  //       <Edit2 size={isMobile ? 14 : 14} />
  //     </MenuHeaderEdit>
  //   </MenuHeaderOrderView>
  // )
}

export default MenuHeaderOrder
