import { useCallback, useEffect, useMemo } from 'react'
import { useSignInForm } from '@open-tender/utils'
import {
  loginCustomer,
  resetLoginError,
  resetOneTimePasscode,
  selectBrand,
  selectCustomer,
  selectGuest,
  selectOneTimePasscode,
  signInCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'
import { FormFieldType } from '@open-tender/types'
import { ThirdPartyLoyaltySignUpMessage } from '../ThirdPartyLoyalty'

const SignInForm = () => {
  const dispatch = useAppDispatch()
  const { tpls } = useAppSelector(selectBrand) || {}
  const hasPunchh = tpls === 'PUNCHH'
  const { email } = useAppSelector(selectGuest)
  const customer = useAppSelector(selectCustomer)
  const signIn = useAppSelector(selectOneTimePasscode)
  const loading = useMemo(
    () => (hasPunchh ? signIn.loading : customer.loading),
    [hasPunchh, customer.loading, signIn.loading]
  )
  const error = useMemo(
    () => (hasPunchh ? signIn.error : customer.error),
    [hasPunchh, customer.error, signIn.error]
  )

  const loginDefault = useCallback(
    (email: string, password: string) =>
      dispatch(loginCustomer({ email, password })),
    [dispatch]
  )
  const loginTpls = useCallback(
    (email: string, password?: string) =>
      dispatch(signInCustomer({ data: { email, password } })),
    [dispatch]
  )
  const login = hasPunchh ? loginTpls : loginDefault

  const showSignUpMessage =
    hasPunchh && error?.code === 'MEMBER_SIGNUP_REQUIRED'

  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useSignInForm(email || '', loading, error, login)

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error) window.scrollTo(0, 0)
  }, [error])

  useEffect(() => {
    dispatch(resetLoginError())
    dispatch(resetOneTimePasscode())
    return () => {
      dispatch(resetLoginError())
      dispatch(resetOneTimePasscode())
    }
  }, [dispatch])

  return (
    <form id="checkout-signin-form" onSubmit={handleSubmit} noValidate>
      {showSignUpMessage ? (
        <ThirdPartyLoyaltySignUpMessage />
      ) : (
        <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      )}
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : 'Sign In'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default SignInForm
