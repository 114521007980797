import { useState } from 'react'
import styled from '@emotion/styled'
import ClipLoader from 'react-spinners/ClipLoader'
import {
  setAddress,
  selectOrder,
  selectSettings,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { GoogleMap, GoogleMapsAutocomplete } from 'components'
import { MapsArrowDiagonal } from '../../icons'

const CateringMapView = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
`

const CateringMap = ({
  mapRef,
}: {
  mapRef: React.RefObject<HTMLDivElement>
}) => (
  <CateringMapView>
    <div ref={mapRef} />
  </CateringMapView>
)

const CateringAutocompleteView = styled.div`
  width: 100%;
`

const CateringAutocompleteInputView = styled.div`
  width: 100%;
`

const CateringAutocompleteInput = ({
  maps,
  map,
  sessionToken,
  autocomplete,
  formattedAddress,
  setCenter,
  setAddress,
}: {
  maps?: typeof google.maps | null
  map?: google.maps.Map | null
  sessionToken?: google.maps.places.AutocompleteSessionToken | null
  autocomplete?: google.maps.places.AutocompleteService | null
  formattedAddress: string
  setCenter: (center: any) => void
  setAddress: (address: any) => void
}) => {
  if (!maps || !map || !sessionToken || !autocomplete) return null

  return (
    <CateringAutocompleteInputView>
      <GoogleMapsAutocomplete
        maps={maps}
        map={map}
        sessionToken={sessionToken}
        autocomplete={autocomplete}
        formattedAddress={formattedAddress}
        setCenter={setCenter}
        setAddress={setAddress}
        icon={<MapsArrowDiagonal size={14} strokeWidth={2} />}
        placeholder="please enter your address"
      />
    </CateringAutocompleteInputView>
  )
}

const CateringAutocomplete = () => {
  const dispatch = useAppDispatch()
  const { address } = useAppSelector(selectOrder)
  const formattedAddress = address ? address.formatted_address : ''
  const { googleMaps } = useAppSelector(selectSettings) || {}
  const { apiKey, defaultCenter, zoom, styles } = googleMaps || {}
  const [, setCenter] = useState(defaultCenter)

  if (!apiKey || !zoom || !styles || !defaultCenter) return null

  return (
    <CateringAutocompleteView>
      <GoogleMap
        apiKey={apiKey}
        zoom={zoom}
        styles={styles}
        center={defaultCenter}
        loader={<ClipLoader size={30} loading={true} />}
        renderMap={(props) => <CateringMap {...props} />}
      >
        <CateringAutocompleteInput
          formattedAddress={formattedAddress ?? ''}
          setCenter={setCenter}
          setAddress={(address) => dispatch(setAddress(address))}
        />
      </GoogleMap>
    </CateringAutocompleteView>
  )
}

export default CateringAutocomplete
