import { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  CreditCardData,
  CreditCardErrors,
  CreditCardValidate,
} from '@open-tender/types'
import { useCreditCard } from '@open-tender/utils'
import { cardIconsMap } from 'components/cardIcons'
import { Checkbox, FormError, Input } from 'components'
import { isMobile } from 'react-device-detect'
import { selectBrand, selectCheckout, useAppSelector } from '@open-tender/cloud'
import { useLocation } from 'react-router-dom'

const CreditCardView = styled.div`
  label: CreditCardView;
  position: relative;
`

const CreditCardType = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  right: ${(props) => props.theme.inputs.paddingHorizontal};
  width: 3.6rem;
  margin-top: -1.2rem;
`

const CreditCardExpCvv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  label {
    width: 48%;
  }
`

const CreditCard = ({
  setCard,
  showDefault = true,
  formErrors = {},
}: {
  setCard: (data: CreditCardValidate & { isComplete: boolean }) => void
  showDefault?: boolean
  formErrors?: CreditCardErrors
}) => {
  const { pathname } = useLocation()
  const isCheckout = pathname === '/checkout'
  const { require_address = false } = useAppSelector(selectBrand) || {}
  const { check } = useAppSelector(selectCheckout)
  const requireAddress = !!check?.config.require_address
  const includeAddress = isCheckout ? requireAddress : require_address
  const {
    data,
    cardType,
    errors,
    disabled,
    handleChange,
    handleBlur,
    isComplete,
  } = useCreditCard(null, {}, false, includeAddress)
  const cardSvg = cardIconsMap[cardType]
  const allErrors = { ...formErrors, ...errors }

  const onChange = (name: keyof CreditCardData, value: string | boolean) => {
    handleChange(name, value)
  }

  const onBlur = (name: keyof CreditCardErrors) => {
    handleBlur(name)
  }

  useEffect(() => {
    setCard({ card: data, cardType, errors, isComplete })
  }, [setCard, data, cardType, errors, isComplete])

  const addressError =
    includeAddress && allErrors.zip
      ? 'Street address or zip code do not match'
      : null

  const zipInput = (
    <Input
      label="Zip Code"
      name="zip"
      type="text"
      pattern="[0-9]*"
      autoComplete="postal-code"
      value={data.zip}
      placeholder=""
      onChange={(evt) => onChange('zip', evt.target.value)}
      onBlur={() => onBlur('zip')}
      error={addressError ? null : allErrors.zip}
      disabled={disabled}
    />
  )

  return (
    <>
      <CreditCardView>
        <Input
          label="Card Number"
          name="acct"
          type="text"
          pattern="[0-9]*"
          autoComplete="cc-number"
          value={data.acct}
          placeholder=""
          onChange={(evt) => onChange('acct', evt.target.value)}
          onBlur={() => onBlur('acct')}
          error={allErrors.acct}
          disabled={disabled}
        >
          {!errors.acct && cardSvg ? (
            <CreditCardType>{cardSvg}</CreditCardType>
          ) : undefined}
        </Input>
        <CreditCardExpCvv>
          <Input
            label={isMobile ? 'Exp. (MM/YY)' : 'Expiration (MM/YY)'}
            name="exp"
            type="text"
            pattern="[0-9]*"
            autoComplete="cc-exp"
            value={data.exp}
            placeholder=""
            onChange={(evt) => onChange('exp', evt.target.value)}
            onBlur={() => onBlur('exp')}
            error={allErrors.exp}
            disabled={disabled}
          />
          <Input
            label="Security Code"
            name="cvv"
            type="text"
            pattern="[0-9]*"
            autoComplete="cc-csc"
            value={data.cvv}
            placeholder=""
            onChange={(evt) => onChange('cvv', evt.target.value)}
            onBlur={() => onBlur('cvv')}
            error={allErrors.cvv}
            disabled={disabled}
          />
        </CreditCardExpCvv>
        {includeAddress ? (
          <>
            <CreditCardExpCvv>
              <Input
                label="Street Address"
                name="addr"
                type="text"
                autoComplete="billing street-address"
                value={data.addr}
                placeholder=""
                onChange={(evt) => onChange('addr', evt.target.value)}
                onBlur={() => onBlur('addr')}
                error={allErrors.addr}
                disabled={disabled}
              />
              {zipInput}
            </CreditCardExpCvv>
            <FormError
              errMsg={addressError}
              style={{ margin: '-1rem 0 3rem' }}
            />
          </>
        ) : (
          zipInput
        )}
      </CreditCardView>
      {showDefault && (
        <Checkbox
          type="checkbox"
          label="Set as Default Card"
          name="is_default"
          on={data.is_default}
          onChange={(evt) => onChange('is_default', evt.target.checked)}
          disabled={disabled}
        />
      )}
    </>
  )
}

export default CreditCard
