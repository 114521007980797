import { Header, HeaderLogo } from '.'
import { Back, Cart, NavMenu, OrderNow } from './buttons'

const HeaderUser = ({ maxWidth = '100%' }: { maxWidth?: string }) => {
  return (
    <Header
      maxWidth={maxWidth}
      title={<HeaderLogo />}
      left={<Back label="Account Settings" path="/account/settings" />}
      right={
        <>
          <Cart />
          <OrderNow />
          <NavMenu />
        </>
      }
    />
  )
}

export default HeaderUser
