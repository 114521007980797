import { useEffect, useState } from 'react'
import { CustomerCreate } from '@open-tender/types'
import { isEmpty } from '@open-tender/utils'
import {
  openModal,
  logoutCustomer,
  resetCheckout,
  selectBrand,
  selectCheckout,
  selectCustomer,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import { ButtonLink, Loading } from 'components'
import CheckoutCompany from './CheckoutCompany'
import CheckoutSection from './CheckoutSection'
import CheckoutSectionFootnote from './CheckoutSectionFootnote'
import CheckoutSectionTitle from './CheckoutSectionTitle'
import CheckoutPhone from './CheckoutPhone'

const CheckoutCustomer = () => {
  const dispatch = useAppDispatch()
  const { has_thanx } = useAppSelector(selectBrand) || {}
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const { auth, profile } = useAppSelector(selectCustomer)
  const { form, check, errors, loading } = useAppSelector(selectCheckout)
  const { sso } = check?.customer || {}
  const noCustomer = isEmpty(form.customer)
  const { customer_id, first_name, last_name, email, phone, company } =
    profile || {}
  const noCustomerId = customer_id && !form.customer.customer_id
  const isLoading = !check && loading === 'pending'
  const showCustomer = check && profile

  useEffect(() => {
    if (!isLoggingOut) {
      if ((noCustomer || noCustomerId) && customer_id) {
        const customer = {
          customer_id,
          first_name,
          last_name,
          email,
          phone,
          company,
        }
        dispatch(updateForm({ customer }))
      }
    }
  }, [
    dispatch,
    noCustomer,
    noCustomerId,
    customer_id,
    first_name,
    last_name,
    email,
    phone,
    company,
    isLoggingOut,
  ])

  useEffect(() => {
    if (has_thanx && customer_id && sso && !sso.connected) {
      dispatch(logoutCustomer(undefined))
    }
  }, [has_thanx, customer_id, sso, dispatch])

  const callback = (data: CustomerCreate) => {
    const { first_name, last_name, email, phone, company } = data
    const customer = {
      customer_id,
      first_name,
      last_name,
      email,
      phone,
      company,
    }
    dispatch(updateForm({ customer }))
    dispatch(validateOrder())
  }

  const update = () => {
    dispatch(openModal({ type: 'profile', args: { callback } }))
  }

  const signOut = () => {
    setIsLoggingOut(true)
    dispatch(resetCheckout())
    dispatch(logoutCustomer(undefined))
  }

  if (!auth) return null

  return isLoading ? (
    <CheckoutSection>
      <Loading text="Loading..." style={{ textAlign: 'left' }} />
    </CheckoutSection>
  ) : showCustomer ? (
    <CheckoutSection title="Contact Info">
      <CheckoutSectionTitle>
        {first_name ?? ''} {last_name ?? ''}
      </CheckoutSectionTitle>
      <CheckoutCompany callback={callback} />
      <p>{email}</p>
      <CheckoutPhone callback={callback} />
      <CheckoutSectionFootnote>
        <p>
          <ButtonLink onClick={update}>Update your contact info</ButtonLink> or{' '}
          <ButtonLink onClick={signOut}>sign out</ButtonLink>
        </p>
      </CheckoutSectionFootnote>
    </CheckoutSection>
  ) : !isLoggingOut && !errors ? (
    <CheckoutSection>
      <Loading text="Retrieving your info..." style={{ textAlign: 'left' }} />
    </CheckoutSection>
  ) : (
    <></>
  )
}

export default CheckoutCustomer
