import { useTheme } from '@emotion/react'
import { MenuItemAllergens, MenuItemImage, MenuItemTags } from 'components'
import MenuItemButtonInfo from './MenuItemButtonInfo'
import {
  MenuItemButtonContainer,
  MenuItemButtonContent,
  MenuItemButtonImage,
  MenuItemButtonTagsAllergens,
  MenuItemButtonView,
} from './MenuItemButton.styled'
import { makeMenuItemLayout } from '@open-tender/utils'

const MenuItemButton = ({
  onClick,
  disabled,
  imageUrl,
  imageOverlay,
  name,
  desc,
  price,
  cals,
  tags,
  allergens,
}: {
  onClick?: () => void
  disabled: boolean
  imageUrl: string
  imageOverlay: JSX.Element | null
  name: string
  desc: string | null
  price: string
  cals: string
  tags: string[]
  allergens: string[]
}) => {
  const theme = useTheme()
  const { position } = theme.menuItems.image
  const { hideImage, flexDirection, alignItems } = makeMenuItemLayout(position)
  const hasTagsAllergens = tags.length || allergens.length ? true : false
  return (
    <MenuItemButtonView onClick={onClick} disabled={disabled}>
      <MenuItemButtonContainer
        flexDirection={flexDirection}
        alignItems={alignItems}
      >
        {!hideImage && (
          <MenuItemButtonImage isVertical={flexDirection.includes('column')}>
            <MenuItemImage imageUrl={imageUrl} alt={name}>
              {imageOverlay}
            </MenuItemImage>
          </MenuItemButtonImage>
        )}
        <MenuItemButtonContent>
          <MenuItemButtonInfo
            name={name}
            desc={desc}
            price={price}
            cals={cals}
          />
          {hasTagsAllergens && (
            <MenuItemButtonTagsAllergens>
              <MenuItemAllergens allergens={allergens} />
              <MenuItemTags tags={tags} />
            </MenuItemButtonTagsAllergens>
          )}
        </MenuItemButtonContent>
      </MenuItemButtonContainer>
    </MenuItemButtonView>
  )
}

export default MenuItemButton
