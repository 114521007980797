import styled from '@emotion/styled'
import { Body, Heading } from 'components'

const LoyaltyHeaderView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0 1rem;
  }
`

const LoyaltyHeaderContent = styled.div`
  text-align: left;
`

const LoyaltyHeaderTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.xBig};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.big};
  }
`

const LoyaltyHeaderSubitle = styled(Body)`
  margin: 0.5rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

interface LoyaltyHeaderProps {
  title?: string
  subtitle?: string
  children?: React.ReactNode
}

const LoyaltyHeader = ({ title, subtitle, children }: LoyaltyHeaderProps) => {
  return (
    <LoyaltyHeaderView>
      <LoyaltyHeaderContent>
        {!!title && <LoyaltyHeaderTitle as="p">{title}</LoyaltyHeaderTitle>}
        {!!subtitle && (
          <LoyaltyHeaderSubitle as="p">{subtitle}</LoyaltyHeaderSubitle>
        )}
      </LoyaltyHeaderContent>
      {children}
    </LoyaltyHeaderView>
  )
}

export default LoyaltyHeader
