import styled from '@emotion/styled'
import { LoyaltyPerk, LoyaltyProgram } from '@open-tender/types'
import { formatDollars } from '@open-tender/utils'
import {
  LoyaltyHeader,
  LoyaltyRewards,
  LoyaltyStatus,
  ProgressSpend,
  SeeMoreLink,
} from 'components'

const LoyaltySpendView = styled.div``

const LoyaltySpend = ({
  program,
  perk,
  to,
}: {
  program: LoyaltyProgram
  perk?: LoyaltyPerk | null
  to?: string
}) => {
  const { name, description, loyalty_type, spend_name } = program
  const { credit, progress, threshold, rewards } = perk || {}
  return (
    <LoyaltySpendView>
      <LoyaltyHeader title={name} subtitle={description} />
      {credit && (
        <LoyaltyStatus
          count={formatDollars(credit, '', 2).replace('.00', '')}
          name="Available Credit"
        >
          {to && <SeeMoreLink text="See Details" to={to} />}
        </LoyaltyStatus>
      )}
      {progress && threshold ? (
        <ProgressSpend
          loyaltyType={loyalty_type}
          spendName={spend_name}
          progress={progress}
          threshold={threshold}
        />
      ) : null}
      {rewards && <LoyaltyRewards rewards={rewards} />}
    </LoyaltySpendView>
  )
}

export default LoyaltySpend
