import styled from '@emotion/styled'
import {
  CheckoutCheck,
  CheckoutTender,
  CheckoutTenders,
  Order,
  OrderTenders,
} from '@open-tender/types'
import {
  checkAmountRemaining,
  makeCheckoutTenderName,
  makeTenderName,
} from '@open-tender/utils'
import CheckSummaryUpdating from './CheckSummaryUpdating'
import CheckSummaryItem from './CheckSummaryItem'
import CheckSummaryTotal from './CheckSummaryTotal'
import CheckoutDonationWidget from 'components/pages/Checkout/CheckoutDonationWidget'
import { isPennStationBrand } from 'utils/helpers'

const CheckSummaryView = styled.div<{ updating?: boolean }>`
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 1rem 0 0;
  border-width: 0;
  border-style: solid;
  border-color: ${(props) => props.theme.border.color};
  border-top-width: ${(props) => props.theme.border.width};
  margin: 2.5rem 0 0;

  & > li {
    opacity: ${(props) => (props.updating ? '0.5' : '1')};
  }
`

const CheckSummary = ({
  check,
  tenders,
  loader,
  updating = false,
  showTenders = true,
}: {
  check: Order | CheckoutCheck
  tenders?: OrderTenders
  loader?: JSX.Element
  updating?: boolean
  showTenders?: boolean
}) => {
  const {
    deposit,
    gift_cards = [],
    surcharges = [],
    discounts = [],
    taxes = [],
    totals,
  } = check
  if (!totals) return null
  const { subtotal, gift_card, surcharge, discount, tip, shipping, total } =
    totals
  const totalBeforeTax = [subtotal, gift_card, surcharge, discount]
    .reduce((t, i) => (t += parseFloat(i)), 0.0)
    .toFixed(2)
  const amountDue = deposit || total
  const amountRemaining = tenders
    ? checkAmountRemaining(amountDue, tenders as CheckoutTenders)
    : null
  const showCheckoutTenders = tenders?.length && amountRemaining !== null

  const showDonationWidget = isPennStationBrand

  return (
    <CheckSummaryView updating={updating}>
      {updating && <CheckSummaryUpdating loader={loader} />}
      <CheckSummaryItem label="Subtotal" value={subtotal} isBold={true} />
      {gift_cards.map((giftCard) => (
        <CheckSummaryItem
          key={giftCard.id}
          label={`Gift Card ${giftCard.card_number}`}
          value={`${giftCard.amount}`}
        />
      ))}
      {surcharges.map((surcharge) => (
        <CheckSummaryItem
          key={surcharge.id}
          label={`${surcharge.name}`}
          value={surcharge.amount}
        />
      ))}
      {discounts.map((discount, index) => (
        <CheckSummaryItem
          key={`${discount.name}-${index}`}
          label={discount.name}
          value={discount.amount || '0.00'}
        />
      ))}
      {subtotal !== totalBeforeTax && (
        <CheckSummaryItem
          label="Total before Tax"
          value={totalBeforeTax}
          isBold={true}
        />
      )}
      {taxes.length
        ? taxes.map((tax) => (
            <CheckSummaryItem
              key={tax.id}
              label={`${tax.name}`}
              value={tax.amount}
            />
          ))
        : null}
      {tip !== '0.00' && <CheckSummaryItem label="Tip" value={tip} />}
      {shipping !== '0.00' && (
        <CheckSummaryItem label="Shipping" value={shipping} />
      )}
      <CheckSummaryTotal label="Total" value={total} />
      {deposit && (
        <CheckSummaryItem
          label="Deposit Amount"
          value={deposit}
          isBold={true}
          style={{ margin: '-1rem 0 2rem' }}
        />
      )}
      {showTenders && (
        <>
          {showCheckoutTenders ? (
            <>
              {tenders.map((tender, index) => (
                <CheckSummaryItem
                  key={`${tender.tender_type}-${tender.amount}-${index}`}
                  label={`${makeCheckoutTenderName(tender as CheckoutTender)}`}
                  value={tender.amount}
                />
              ))}
              <CheckSummaryItem
                label="Remaining Amount Due"
                value={amountRemaining.toFixed(2)}
                isBold={true}
              />
            </>
          ) : (
            check.tenders.map((tender, index) => (
              <CheckSummaryItem
                key={`${tender.tender_type}-${tender.amount}-${index}`}
                label={`${makeTenderName(tender)}`}
                value={tender.amount}
              />
            ))
          )}
        </>
      )}
      {showDonationWidget && <CheckoutDonationWidget />}
    </CheckSummaryView>
  )
}

export default CheckSummary
