import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  setAddress,
  selectOrder,
  setOrderServiceType,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, GoogleMapsAutocomplete } from 'components'
import { MapsArrowDiagonal } from '../../icons'
import { RevenueCenters, ServiceType } from '@open-tender/types'

const LocationsAutocompleteView = styled.div`
  position: absolute;
  z-index: 3;
  bottom: ${(props) => props.theme.layout.margin};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${(props) => props.theme.layout.padding};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    bottom: ${(props) => props.theme.layout.marginMobile};
    padding: 0 ${(props) => props.theme.layout.paddingMobile};
  }
`

const LocationsAutocompleteForm = styled.div`
  width: 100%;
  max-width: 72rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 2rem;
  // border-radius: 1rem;
  // background-color: ${(props) => props.theme.overlay.dark};

  label {
    display: block
    flex: 1 1 auto;
    color: ${(props) => props.theme.colors.light};
  }

  input {
    color: ${(props) => props.theme.colors.light};
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.light};

    &:active,
    &:focus {
      background-color: transparent;
      border-color: ${(props) => props.theme.colors.light};
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.light};
    }
  }
`

const LocationsAutocompleteButtons = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LocationsAutocompleteToggle = styled.div`
  flex: 0 0 auto;
  margin: 0 2rem;

  button {
    &:first-of-type {
      border-radius: 0;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      border-right: 0;
    }

    &:last-of-type {
      border-radius: 0;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
`

interface LocationsAutocompleteButtonProps {
  isActive?: boolean
}

const LocationsAutocompleteButton = styled.button<LocationsAutocompleteButtonProps>`
  padding: 0.7rem 1.4rem 0.7rem;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  border-width: 0.1rem;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.light};
  color: ${(props) =>
    props.isActive ? props.theme.colors.dark : props.theme.colors.light};
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.light : 'transparent'};
`

interface LocationsAutocompleteProps {
  revenueCenters?: RevenueCenters
  setCenter: ({ lat, lng }: { lat: number; lng: number }) => void
  maps?: typeof google.maps | null
  map?: google.maps.Map | null
  sessionToken?: google.maps.places.AutocompleteSessionToken | null
  autocomplete?: google.maps.places.AutocompleteService | null
  center?: { lat: number; lng: number }
}

const LocationsAutocomplete = ({
  setCenter,
  maps,
  map,
  sessionToken,
  autocomplete,
}: LocationsAutocompleteProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { address, serviceType } = useAppSelector(selectOrder)
  const [locSt, setLocSt] = useState(serviceType || 'PICKUP')
  const formattedAddress = address ? address.formatted_address : ''
  const placeholder =
    serviceType === 'DELIVERY'
      ? 'enter a delivery address'
      : 'enter an address or zip code'

  const toggle = (
    evt: React.MouseEvent<HTMLButtonElement>,
    st: ServiceType
  ) => {
    evt.preventDefault()
    setLocSt(st)
  }

  const order = () => {
    dispatch(setOrderServiceType({ orderType: 'OLO', serviceType: locSt }))
    navigate('/locations')
  }

  useEffect(() => {
    if (!serviceType || serviceType === 'WALKIN') {
      setLocSt('PICKUP')
      dispatch(setOrderServiceType({ orderType: 'OLO', serviceType: 'PICKUP' }))
    }
  }, [dispatch, serviceType])

  if (!maps || !map || !sessionToken || !autocomplete) return null

  return (
    <LocationsAutocompleteView>
      <LocationsAutocompleteForm>
        <GoogleMapsAutocomplete
          maps={maps}
          map={map}
          sessionToken={sessionToken}
          autocomplete={autocomplete}
          formattedAddress={formattedAddress || ''}
          setAddress={(address) => dispatch(setAddress(address))}
          setCenter={setCenter}
          icon={<MapsArrowDiagonal size={14} strokeWidth={2} />}
          placeholder={placeholder}
        />
        <LocationsAutocompleteButtons>
          <LocationsAutocompleteToggle>
            <LocationsAutocompleteButton
              onClick={(evt) => toggle(evt, 'PICKUP')}
              isActive={locSt === 'PICKUP'}
            >
              Pickup
            </LocationsAutocompleteButton>
            <LocationsAutocompleteButton
              onClick={(evt) => toggle(evt, 'DELIVERY')}
              isActive={locSt === 'DELIVERY'}
            >
              Delivery
            </LocationsAutocompleteButton>
          </LocationsAutocompleteToggle>
          <Button
            onClick={order}
            size="small"
            color="primary"
            disabled={!address || !locSt}
          >
            Go
          </Button>
        </LocationsAutocompleteButtons>
      </LocationsAutocompleteForm>
    </LocationsAutocompleteView>
  )
}

export default LocationsAutocomplete
