import { useEffect } from 'react'
import styled from '@emotion/styled'
import { SkipLink, Footer } from '.'
import { maybeRefreshVersion } from '../app/version'

interface ContentViewProps {
  maxWidth: string
}

const ContentView = styled.div<ContentViewProps>`
  label: ContentView;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.maxWidth};
  background-color: ${(props) => props.theme.bgColors.primary};
`

const Content = ({
  maxWidth = '100%',
  scrollTop = true,
  hasRouter = true,
  hasFooter = true,
  children,
  style = undefined,
}: {
  maxWidth?: string
  scrollTop?: boolean
  hasRouter?: boolean
  hasFooter?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
}) => {
  useEffect(() => {
    if (scrollTop) window.scrollTo(0, 0)
    maybeRefreshVersion()
  }, [scrollTop])

  return (
    <ContentView maxWidth={maxWidth} style={style}>
      <>
        <SkipLink />
        {children}
        {hasFooter && <Footer hasRouter={hasRouter} />}
      </>
    </ContentView>
  )
}

export default Content
