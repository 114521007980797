import styled from '@emotion/styled'
import { Button } from 'components'

const ButtonLargeContent = styled.span`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.theme.welcome.desktop.showIcons ? 'space-between' : 'center'};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: ${(props) =>
      props.theme.welcome.mobile.showIcons ? 'space-between' : 'center'};
  }
  span {
    display: block;
  }

  span + span {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    line-height: 0;
    display: ${(props) =>
      props.theme.welcome.desktop.showIcons ? 'block' : 'none'};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      display: ${(props) =>
        props.theme.welcome.mobile.showIcons ? 'block' : 'none'};
    }
  }
`

interface ButtonLargeProps {
  onClick: () => void
  text: string
  children: JSX.Element | JSX.Element[]
  color?: string
}

const ButtonLarge = ({
  onClick,
  text,
  children,
  color = 'secondary',
}: ButtonLargeProps) => {
  return (
    <Button onClick={onClick} size="big" color={color}>
      <ButtonLargeContent>
        <span>{text}</span>
        <span>{children}</span>
      </ButtonLargeContent>
    </Button>
  )
}

export default ButtonLarge
