import { selectOneTimePasscode, useAppSelector } from '@open-tender/cloud'
import { ModalContent, OneTimePasscodeForm, Body } from 'components'

const LoginOTP = ({ callback }: { callback?: () => void }) => {
  const { otpSent } = useAppSelector(selectOneTimePasscode)
  return (
    <ModalContent
      title="Sign into your account"
      subtitle={
        <Body as="p" size="small">
          {otpSent
            ? 'Enter the one-time passcode received via your phone number or email address used for signing in'
            : "Enter your phone number or email address, and we'll send you a security code to complete the sign in process."}
        </Body>
      }
    >
      <OneTimePasscodeForm callback={callback} />
    </ModalContent>
  )
}

export default LoginOTP
