import styled from '@emotion/styled'
import {
  CreditCard,
  CreditCardUpdate,
  CreditCards as CreditCardsType,
} from '@open-tender/types'
import {
  updateCustomerCreditCard,
  removeCustomerCreditCard,
  useAppDispatch,
} from '@open-tender/cloud'
import { Checkmark, ButtonLink, Preface, LinkSeparator, Row } from 'components'
import { cardIconsMap } from '../../cardIcons'

const CreditCardIcon = styled.span`
  display: block;
  width: 6rem;
  min-width: 6rem;
  // height: auto;
`

const CreditCards = ({
  creditCards,
  isLoading,
  showDefault = true,
}: {
  creditCards: CreditCardsType
  isLoading?: boolean
  showDefault?: boolean
}) => {
  const dispatch = useAppDispatch()

  const handleDefault = (creditCard: CreditCard) => {
    const cardId = creditCard.customer_card_id
    const data: CreditCardUpdate = { is_default: true }
    dispatch(updateCustomerCreditCard({ cardId, data }))
  }

  const handleDelete = (creditCard: CreditCard) => {
    const cardId = creditCard.customer_card_id
    dispatch(removeCustomerCreditCard({ cardId }))
  }

  return (
    <div>
      {creditCards.map((creditCard) => (
        <Row
          key={creditCard.customer_card_id}
          icon={
            <CreditCardIcon>
              {cardIconsMap[creditCard.card_type ?? 'VISA']}
            </CreditCardIcon>
          }
          content={
            <>
              {creditCard.is_default && (
                <Preface
                  size="xSmall"
                  style={{
                    display: 'inline-block',
                    margin: '0 1.0rem 0.3rem 0',
                  }}
                >
                  Primary
                </Preface>
              )}
              <p className="title">
                {creditCard.card_type_name} ending in {creditCard.last4}
                {creditCard.is_default && (
                  <span style={{ paddingLeft: '0.5rem' }}>
                    <Checkmark />
                  </span>
                )}
              </p>
              <p>{creditCard.masked}</p>
              <p>
                {showDefault && (
                  <>
                    <ButtonLink
                      onClick={() => handleDefault(creditCard)}
                      disabled={creditCard.is_default || isLoading}
                    >
                      make primary
                    </ButtonLink>
                    <LinkSeparator />
                  </>
                )}

                <ButtonLink
                  onClick={() => handleDelete(creditCard)}
                  disabled={isLoading}
                >
                  remove
                </ButtonLink>
              </p>
            </>
          }
        />
      ))}
    </div>
  )
}

export default CreditCards
