import styled from '@emotion/styled'
import ScrollableSectionHeader from './ScrollableSectionHeader'
import Loading from './Loading'

const ScrollableSectionView = styled.div`
  margin: 0 -${(props) => props.theme.layout.padding} -1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 -${(props) => props.theme.layout.paddingMobile} -1.5rem;
  }
`

const ScrollableSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: auto;
  margin: -0.5rem 0 0;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ScrollableSectionItem = styled.div`
  flex: 0 0 31rem;
  padding: 1.5rem 0;
  margin-right: ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex: 0 0 20rem;
    margin-right: ${(props) => props.theme.layout.paddingMobile};
  }

  &:first-of-type {
    margin-left: ${(props) => props.theme.layout.padding};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin-left: ${(props) => props.theme.layout.paddingMobile};
    }
  }
`

const ScrollableSectionLoading = styled.div`
  padding: 2rem ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem ${(props) => props.theme.layout.paddingMobile};
  }
`

const ScrollableSection = <T extends {}>({
  title,
  to,
  items,
  renderItem,
  keyName,
  isLoading,
  loadingText,
}: {
  title?: string
  to?: string
  items: T[]
  renderItem: (item: any) => JSX.Element
  keyName: string
  isLoading?: boolean
  loadingText?: string
}) => {
  const hasItems = items && items.length > 0 ? true : false
  return (
    <ScrollableSectionView>
      <ScrollableSectionHeader title={title} to={to} />
      {hasItems ? (
        <ScrollableSectionContainer>
          {items.map((item, index) => (
            <ScrollableSectionItem
              key={`${item[keyName as keyof T] as string}-${index}`}
            >
              {renderItem({ item })}
            </ScrollableSectionItem>
          ))}
        </ScrollableSectionContainer>
      ) : isLoading ? (
        <ScrollableSectionLoading>
          <Loading text={loadingText ?? ''} style={{ textAlign: 'left' }} />
        </ScrollableSectionLoading>
      ) : null}
    </ScrollableSectionView>
  )
}

export default ScrollableSection
