import styled from '@emotion/styled'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import { ButtonLink } from 'components'

const DeleteAccountView = styled.div`
  text-align: center;
  margin: ${(props) => props.theme.layout.margin} 0;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }

  button {
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

export interface DeleteAccountProps {
  className?: string
}

const DeleteAccount = ({ className }: DeleteAccountProps) => {
  const dispatch = useAppDispatch()

  const openDelete = () => {
    dispatch(openModal({ type: 'deleteAccount' }))
  }

  return (
    <DeleteAccountView className={className}>
      <ButtonLink color="primary" onClick={openDelete}>
        Delete Your Account
      </ButtonLink>
    </DeleteAccountView>
  )
}

export default DeleteAccount
