import styled from '@emotion/styled'
import { BgImage, Box } from 'components'
import Body from '../Body'
import Heading from '../Heading'

export const RevenueCenterView = styled(Box)<{
  isMenu?: boolean
  isActive?: boolean
  hasBox?: boolean
}>`
  position: relative;
  overflow: hidden;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    ${(props) => (props.hasBox ? 'padding: 1.5rem' : '')};
  }
`

export const RevenueCenterImage = styled(BgImage)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 24rem;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`

export const RevenueCenterContent = styled.div<{
  showImage?: boolean
  hasBox?: boolean
}>`
  padding: 0 ${(props) => (props.showImage ? `24rem` : null)} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
  }

  & > div {
    padding: ${(props) => (props.hasBox ? '2rem' : '0 2rem 0 0')};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 0;
    }
  }
`

export const RevenueCenterHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
`

export const RevenueCenterTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.big};
  ${(props) =>
    props.theme.cards.default.overrideFontColors
      ? `color: ${props.theme.cards.default.titleColor}; font-size: ${props.theme.cards.default.titleSize};`
      : ''};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.big};
    ${(props) =>
      props.theme.cards.default.overrideFontColors
        ? `font-size: ${props.theme.cards.default.titleSizeMobile};`
        : ''};
  }
`

export const RevenueCenterFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RevenueCenterFlexContent = styled.div`
  flex: 1 1 auto;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-right: 1rem;
  }
`

export const RevenueCenterActions = styled.div`
  label: RevenueCenterActions;

  a,
  button {
    display: block;
    width: 100%;
    text-align: left;
    text-decoration: none;
  }
`

export const RevenueCenterDistanceContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 0.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 0.6rem;
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

export const RevenueCenterDistance = styled(Body)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

export const RevenueCenterStatus = styled(Body)<{ color: string }>`
  color: ${(props) => props.theme.colors[props.color]};
  font-size: ${(props) => props.theme.fonts.sizes.small};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

export const RevenueCenterDesc = styled.div`
  margin: 1.5rem 0 0;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: 1rem;
  }

  p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    ${(props) =>
      props.theme.cards.default.overrideFontColors
        ? `color: ${props.theme.cards.default.textColor}; font-size: ${props.theme.cards.default.textSize};`
        : ''};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.xSmall};
      ${(props) =>
        props.theme.cards.default.overrideFontColors
          ? `font-size: ${props.theme.cards.default.textSizeMobile};`
          : ''};
    }
  }
`
