export const getActiveElement = (elements: HTMLElement[], offset = 0) => {
  return elements
    .filter((i) => i.getBoundingClientRect().top <= offset)
    .reduce((max: HTMLElement | null, i: HTMLElement) => {
      return max &&
        max.getBoundingClientRect().top > i.getBoundingClientRect().top
        ? max
        : i
    }, null)
}

// https://stackoverflow.com/questions/51229742/javascript-window-scroll-behavior-smooth-not-working-in-safari
export const smoothHorizontalScrolling = (
  container: HTMLElement,
  time: number,
  amount: number,
  start: number
) => {
  let eAmt = amount / 100
  let curTime = 0
  let scrollCounter = 0
  while (curTime <= time) {
    window.setTimeout(shs, curTime, container, scrollCounter, eAmt, start)
    curTime += time / 100
    scrollCounter++
  }
}

const shs = (
  elem: HTMLElement,
  scrollCounter: number,
  eAmt: number,
  start: number
) => {
  const scrolledAmount = eAmt * scrollCounter + start
  elem.scrollLeft = scrolledAmount
}

export const generateUUID = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

export const generateRandomSessionID = () => {
  return Math.floor(Math.random() * 9000000000) + 1000000000
}

export const sha256 = async (value: string) => {
  const utf8 = new TextEncoder().encode(value)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}
export const isPennStationBrand = window.location.href.includes('penn-station')
