import { useTheme } from '@emotion/react'
import { CartItem } from '@open-tender/types'
import { hasGroupsBelowMin, useBuilder, useOrderItem } from '@open-tender/utils'
import {
  addItemToCart,
  selectDisplaySettings,
  selectSelectedAllergenNames,
  showNotification,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Button, MenuItemOverlay, MenuItemTagAlert } from 'components'
import {
  UpsellItemButtons,
  UpsellItemSizes,
  UpsellItemView,
} from './UpsellItem.styled'
import UpsellItemCard from './UpsellItemCard'
import { useEffect, useState } from 'react'

const UpsellItem = ({
  orderItem,
  addCallback,
}: {
  orderItem: CartItem
  addCallback?: () => void
}) => {
  const dispatch = useAppDispatch()
  const [hasSize, setHasSize] = useState<boolean>(false)
  const theme = useTheme()
  const { image } = theme.menuItems
  const showImage = image.position !== 'HIDDEN'
  const displaySettings = useAppSelector(selectDisplaySettings)
  const {
    calories: showCals = false,
    tags: showTags = false,
    allergens: showAllergens = false,
  } = displaySettings || {}
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const { item: builtItem, toggleOption } = useBuilder(orderItem)
  const {
    name,
    imageUrl,
    displayTags,
    displayAllergens,
    displayPrice,
    displayCals,
    isSoldOut,
    allergenAlert,
  } = useOrderItem(
    orderItem,
    null,
    allergenAlerts,
    {},
    showCals,
    showTags,
    showAllergens
  )
  const { quantity, groups } = builtItem
  const sizeGroup = groups?.find((i) => i.isSize)
  const groupsBelowMin = hasGroupsBelowMin(null, groups)
  const isIncomplete = `${quantity}` === '' || quantity === 0 || groupsBelowMin

  const imageOverlay = showImage ? (
    <MenuItemOverlay
      isSoldOut={isSoldOut}
      allergenAlert={allergenAlert ?? undefined}
    />
  ) : null

  const add = () => {
    if (!isIncomplete) {
      dispatch(addItemToCart(builtItem))
      dispatch(showNotification(`${builtItem.name} added to cart!`))
      addCallback?.()
    }
  }

  const addSize = (optionId: number) => {
    if (!sizeGroup) return
    toggleOption([[sizeGroup.id, optionId]])
    setHasSize(true)
  }

  useEffect(() => {
    if (hasSize && !isIncomplete) {
      dispatch(addItemToCart(builtItem))
      dispatch(showNotification(`${builtItem.name} added to cart!`))
      addCallback?.()
    }
  }, [hasSize, isIncomplete, builtItem, addCallback, dispatch])

  if (isIncomplete && !sizeGroup) return null

  return (
    <UpsellItemView>
      <UpsellItemCard
        showImage={showImage}
        imageUrl={imageUrl ?? ''}
        imageOverlay={imageOverlay}
        name={name ?? ''}
        price={displayPrice}
        cals={displayCals ?? ''}
        tags={displayTags}
        allergens={displayAllergens}
      />
      {!showImage ? (
        <MenuItemTagAlert
          isSoldOut={isSoldOut}
          allergenAlert={allergenAlert ?? ''}
        />
      ) : null}
      <UpsellItemButtons>
        {!isIncomplete ? (
          <Button onClick={add} size="small">
            Add To Order
          </Button>
        ) : (
          <UpsellItemSizes>
            {sizeGroup?.options?.map((option) => (
              <Button
                key={option.id}
                onClick={() => addSize(option.id)}
                size="small"
              >
                {option.name}
              </Button>
            ))}
          </UpsellItemSizes>
        )}
      </UpsellItemButtons>
    </UpsellItemView>
  )
}

export default UpsellItem
