import styled from '@emotion/styled'
import { MenuCategory as MenuCategoryType } from '@open-tender/types'
import { containsAny } from '@open-tender/utils'
import {
  selectSelectedAllergenNames,
  selectSelectedTagNames,
  useAppSelector,
} from '@open-tender/cloud'
import MenuCategoryHeader from './MenuCategoryHeader'
import MenuItems from './MenuItems'
import MenuItem from './MenuItem/MenuItem'
import sortBy from 'lodash/sortBy'
import { useEffect } from 'react'
import { sendMenuScroll, sendMenuView } from 'app/analytics'

export const MenuCategoryView = styled.div`
  label: MenuCategoryView;
  width: 100%;
  max-width: ${(props) => props.theme.menuItems.container.maxWidth};
  padding: ${(props) => props.theme.menuItems.container.padding};
  margin: ${(props) => props.theme.menuItems.container.margin};
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.menuItems.container.paddingMobile};
    margin: ${(props) => props.theme.menuItems.container.marginMobile};
    margin-left: auto;
    margin-right: auto;
  }
`

export const MenuCategoryWithHeaderView = styled.div`
  margin-top: ${(props) => props.theme.layout.navHeight};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: ${(props) => props.theme.layout.navHeightMobile};
  }
`

const hide_allergic_items = false

const MenuCategory = ({
  category,
  isChild,
}: {
  category: Partial<MenuCategoryType>
  isChild?: boolean
}) => {
  const { name, description } = category
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const selectedTags = useAppSelector(selectSelectedTagNames)

  let items = selectedTags.length
    ? category.items?.filter((i) => {
        const itemTags = i.tags?.split(', ') || []
        return containsAny(itemTags, selectedTags)
      })
    : category.items

  items =
    allergenAlerts.length && hide_allergic_items
      ? items?.filter((item) => {
          const itemAllergens = item.allergens?.split(', ') || []
          return !containsAny(itemAllergens, allergenAlerts)
        })
      : items

  const menuItems = items
    ? sortBy(items, 'menu_position').map((i) => ({
        ...i,
        category_id: category.id,
        category_name: category.name,
      }))
    : []

  useEffect(() => {
    sendMenuView({ category, items: menuItems })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  useEffect(() => {
    const handleScroll = () => {
      sendMenuScroll()
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!menuItems || !menuItems.length) return null

  return (
    <MenuCategoryView>
      <MenuCategoryHeader
        title={name}
        subtitle={description}
        isChild={isChild}
      />
      <MenuItems>
        {menuItems.map((item, index) => (
          <MenuItem
            key={`${item.id}-${index}`}
            item={item}
            favorite={item.favorite}
            allergenAlerts={allergenAlerts}
          />
        ))}
      </MenuItems>
    </MenuCategoryView>
  )
}

export default MenuCategory
