import { CartItemGroup, CartItemOption, CartLevels } from '@open-tender/types'
import {
  PizzaBuilderOptionClose,
  PizzaBuilderOptionCoverage,
  PizzaBuilderOptionCoverageView,
  PizzaBuilderOptionTitle,
  PizzaBuilderOptionView,
} from './PizzaBuilderOption.styled'
import React from 'react'
import { Coverage } from './types'
import { useBuilder, useModifier } from '@open-tender/utils'
import { selectSelectedAllergenNames, useAppSelector } from '@open-tender/cloud'

const PizzaBuilderOption = ({
  option,
  group,
  levels = [],
  builder,
}: {
  option: CartItemOption
  group: CartItemGroup
  levels: CartLevels | undefined
  builder: ReturnType<typeof useBuilder>
}) => {
  const { groups } = option

  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const { updatedLevels } = useModifier(
    group,
    option,
    levels,
    builder.toggleOption,
    builder.incrementOption,
    builder.decrementOption,
    builder.setOptionQuantity,
    allergenAlerts
  )

  const coverageGroup = groups.find((group) => group.name === 'Coverage')
  const coverageOption = coverageGroup?.options.find(
    (option) => option.quantity === 1
  )
  const coverage = (coverageOption?.slug as Coverage | undefined) ?? 'whole'

  const handleOnClear = () => {
    if (!coverageOption || !coverageGroup) return
    builder.decrementOption([
      ...updatedLevels,
      [coverageGroup.id, coverageOption.id],
    ])
  }

  return (
    <PizzaBuilderOptionView onClick={handleOnClear}>
      <PizzaBuilderOptionClose strokeWidth={1.5} size={20} />
      <PizzaBuilderOptionTitle>{option.name}</PizzaBuilderOptionTitle>
      <PizzaBuilderOptionCoverageView>
        <PizzaBuilderOptionCoverage coverage={coverage} />
      </PizzaBuilderOptionCoverageView>
    </PizzaBuilderOptionView>
  )
}

export default PizzaBuilderOption
