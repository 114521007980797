import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import {
  closeGroupOrder,
  selectGroupOrder,
  selectMenuSlug,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Header, HeaderLogo } from 'components'
import { Back, Cart, NavMenu } from 'components/buttons'

const CheckoutHeader = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const menuSlug = useAppSelector(selectMenuSlug)
  const { cartId } = useAppSelector(selectGroupOrder)

  const reopen = () => {
    if (!cartId) return
    dispatch(closeGroupOrder({ cartId, closed: false })).then(() =>
      navigate('/review')
    )
  }

  const back = cartId ? (
    <Back
      onClick={reopen}
      label="Reopen Group Order"
      text={isMobile ? null : 'Reopen Group Order'}
    />
  ) : (
    <Back
      path={menuSlug}
      label="Back to Menu"
      text={isMobile ? null : 'Back To Menu'}
    />
  )

  return (
    <Header
      title={<HeaderLogo />}
      left={back}
      right={
        <>
          {isMobile && <Cart />}
          <NavMenu />
        </>
      }
    />
  )
}

export default CheckoutHeader
