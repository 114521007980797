import styled from '@emotion/styled'
import {
  CheckoutTenderGiftCard,
  Money,
  isGiftCardCheckoutTender,
} from '@open-tender/types'
import { checkAmountRemaining } from '@open-tender/utils'
import {
  selectCheckout,
  selectContent,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import CheckoutSection from '../CheckoutSection'
import CheckoutValutecCard from './CheckoutValutecCard'
import { useMemo } from 'react'
import CheckoutValutecAppliedCard from './CheckoutValutecAppliedCard'

const CheckoutValutecView = styled.div`
  margin: 1.5rem 0 0;
`

const CheckoutValutec = () => {
  const dispatch = useAppDispatch()
  const { checkout: config } = useAppSelector(selectContent) || {}
  const { check, form } = useAppSelector(selectCheckout)
  const total = check?.totals.total
  const deposit = check?.deposit
  const amountDue = deposit || total
  const amountRemaining = amountDue
    ? checkAmountRemaining(amountDue, form.tenders)
    : 0.0
  const isPaid = Math.abs(amountRemaining).toFixed(2) === '0.00'

  const applyGiftCard = async (
    cardNumber: string,
    pin: string,
    balance: Money | null
  ) => {
    if (!amountDue) return
    const giftCardTenders = form.tenders.filter(
      (i) => i.tender_type === 'GIFT_CARD'
    )
    let remaining = giftCardTenders.length
      ? checkAmountRemaining(amountDue, giftCardTenders)
      : parseFloat(amountDue)
    const amount = balance
      ? (Math.min(remaining, parseFloat(balance)).toFixed(2) as Money)
      : (remaining.toFixed(2) as Money)
    if (parseFloat(amount) <= 0.0) return
    const newGiftCard = {
      tender_type: 'GIFT_CARD',
      card_number: cardNumber,
      pin: pin,
      balance: balance || amount,
      amount: amount,
    } as const

    await dispatch(updateForm({ tenders: [...form.tenders, newGiftCard] }))
    dispatch(validateOrder())
  }

  const removeGiftCard = (cardNumber: string) => {
    if (!amountDue) return
    const filtered = form.tenders.filter(
      (i) => !isGiftCardCheckoutTender(i) || i.card_number !== cardNumber
    )
    const nonGiftCard = filtered.filter((i) => i.tender_type !== 'GIFT_CARD')
    const giftCard = filtered.filter(
      (i) => i.tender_type === 'GIFT_CARD'
    ) as CheckoutTenderGiftCard[]

    let remaining = checkAmountRemaining(amountDue, nonGiftCard)
    const adjusted = giftCard.map((i) => {
      const newAmount = Math.min(remaining, parseFloat(`${i.balance}`))
      remaining -= newAmount
      return { ...i, amount: newAmount.toFixed(2) as Money }
    })

    const nonZero = adjusted.filter((i) => i.amount !== '0.00')
    const adjustedOther = nonGiftCard.map((i) => {
      const newAmount = parseFloat(i.amount) + remaining
      remaining = 0.0
      return { ...i, amount: newAmount.toFixed(2) as Money }
    })
    const nonZeroOther = adjustedOther.filter((i) => i.amount !== '0.00')

    dispatch(updateForm({ tenders: [...nonZeroOther, ...nonZero] }))
    dispatch(validateOrder())
  }

  const appliedCards = useMemo<CheckoutTenderGiftCard[]>(() => {
    return check?.tenders.filter(
      (i) => i.tender_type === 'GIFT_CARD'
    ) as CheckoutTenderGiftCard[]
  }, [check?.tenders])

  return (
    <CheckoutSection title={config?.giftCards.title}>
      <CheckoutValutecView>
        {appliedCards.map((applied, index) => (
          <CheckoutValutecAppliedCard
            key={index}
            applied={applied}
            removeGiftCard={removeGiftCard}
          />
        ))}
        <CheckoutValutecCard applyGiftCard={applyGiftCard} isPaid={isPaid} />
      </CheckoutValutecView>
    </CheckoutSection>
  )
}

export default CheckoutValutec
