import { formatDollars, formatQuantity } from '@open-tender/utils'
import { TriangleDown } from './icons'
import {
  ProgressPointsView,
  ProgressPointsFill,
  ProgressPoint,
  ProgressPointText,
  ProgressPointCircle,
  ProgressPointPoints,
  ProgressPointFootnote,
} from './ProgressPoints'
import { LoyaltyType } from '@open-tender/types'

type ProgressSpendPoints = {
  count: number
  percent: number
  percentage: string
}[]

const ProgressSpend = ({
  loyaltyType,
  spendName,
  progress,
  threshold,
}: {
  loyaltyType: LoyaltyType
  spendName: string
  progress: string
  threshold: string
}) => {
  const s = parseInt(progress)
  const t = parseInt(threshold)
  const remaining = t - s
  const progressPercent = Math.min((s / t) * 100, 100)
  const style = { width: `${progressPercent || 0}%` }
  const isDollars = loyaltyType === 'DOLLARS'
  const isOrders = loyaltyType === 'ORDERS'
  const fmt = isDollars
    ? (t: number) => formatDollars(`${t}`, '', 0)
    : (t: number) => formatQuantity(`${t}`)
  const suffix = isDollars ? '' : ` ${spendName}`
  const thresholds = isOrders ? Array.from(Array(t)) : []
  const points: ProgressSpendPoints = thresholds.reduce((arr, i, index) => {
    const count = index + 1
    const percent = (count / t) * 100
    const percentage = `${percent.toFixed(5)}%`
    return [...arr, { count, percent, percentage }]
  }, [] as ProgressSpendPoints)

  return (
    <>
      <ProgressPointsView style={{ margin: '4rem 0 0' }}>
        <div style={style}>
          <ProgressPointsFill />
        </div>
        {!isOrders ? (
          <ProgressPoint style={{ left: '100%' }} isLast={true}>
            <ProgressPointText>{fmt(t)}</ProgressPointText>
            <ProgressPointCircle />
          </ProgressPoint>
        ) : (
          points.map((i, index) => (
            <ProgressPoint
              key={i.percentage}
              style={{ left: i.percentage }}
              isFirst={false}
              isLast={false}
            >
              <ProgressPointText>{i.count}</ProgressPointText>
              <ProgressPointCircle isFilled={i.percent <= progressPercent} />
            </ProgressPoint>
          ))
        )}
        {!isOrders && (
          <ProgressPoint style={{ left: `${progressPercent}%` }}>
            <ProgressPointPoints>{fmt(s)}</ProgressPointPoints>
            <TriangleDown />
          </ProgressPoint>
        )}
      </ProgressPointsView>
      <ProgressPointFootnote>
        <p>
          {fmt(remaining)}
          {suffix} away from your next reward!
        </p>
      </ProgressPointFootnote>
    </>
  )
}

export default ProgressSpend
