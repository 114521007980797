import { useCallback, useEffect, useMemo } from 'react'
import { FormFieldType } from '@open-tender/types'
import { useLoginForm } from '@open-tender/utils'
import {
  closeModal,
  loginCustomer,
  loginCustomerThanx,
  resetLoginError,
  resetOneTimePasscode,
  selectBrand,
  selectCustomer,
  selectOneTimePasscode,
  signInCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { ButtonSubmit, FormError, FormInputs, FormSubmit } from 'components'
import { ThirdPartyLoyaltySignUpMessage } from 'components/ThirdPartyLoyalty'

const LoginForm = ({ callback }: { callback?: () => void }) => {
  const dispatch = useAppDispatch()
  const { has_thanx, tpls } = useAppSelector(selectBrand) || {}
  const hasPunchh = tpls === 'PUNCHH'
  const customer = useAppSelector(selectCustomer)
  const signIn = useAppSelector(selectOneTimePasscode)
  const loading = useMemo(
    () => (hasPunchh ? signIn.loading : customer.loading),
    [hasPunchh, customer.loading, signIn.loading]
  )
  const error = useMemo(
    () => (hasPunchh ? signIn.error : customer.error),
    [hasPunchh, customer.error, signIn.error]
  )
  const showSignUpMessage =
    hasPunchh && error?.code === 'MEMBER_SIGNUP_REQUIRED'

  const loginDefault = useCallback(
    (email: string, password?: string) =>
      dispatch(loginCustomer({ email, password: password as string })),
    [dispatch]
  )
  const loginThanx = useCallback(
    (email: string) => dispatch(loginCustomerThanx({ email })),
    [dispatch]
  )
  const loginTpls = useCallback(
    (email: string, password?: string) =>
      dispatch(signInCustomer({ data: { email, password } })),
    [dispatch]
  )
  const login = has_thanx ? loginThanx : hasPunchh ? loginTpls : loginDefault

  const loginCallback = callback || (() => dispatch(closeModal()))

  const {
    submitRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useLoginForm(loading, error, login, loginCallback, !has_thanx)
  const errMsg = error?.message || errors.form || ''

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    dispatch(resetLoginError())
    dispatch(resetOneTimePasscode())
    return () => {
      dispatch(resetLoginError())
      dispatch(resetOneTimePasscode())
    }
  }, [dispatch])

  return (
    <form id="login-form" onSubmit={handleSubmit} noValidate>
      {showSignUpMessage ? (
        <ThirdPartyLoyaltySignUpMessage />
      ) : (
        <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      )}
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default LoginForm
