import { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  fetchCustomerQRCode,
  openModal,
  selectBrand,
  selectContentSection,
  selectCustomerQRCode,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Grid } from '../../icons'
import Button from 'components/Button'
import HeaderLogo from 'components/HeaderLogo'

const AccountScanView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: slide-down 0.25s ease-in-out 0.125s forwards;

  button {
    padding: 0.5rem 1rem;
  }
`

const AccountScanIcon = styled.span`
  display: block;
  margin: 0 0.7rem 0 0;
  line-height: 0;
`

const AccountScanText = styled.span`
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

const AccountScan = () => {
  const dispatch = useAppDispatch()
  const { has_app = false } = useAppSelector(selectBrand) || {}
  const { qrcode, loading, error } = useAppSelector(selectCustomerQRCode)
  const { displayLogoMobile: showLogo = false } =
    useAppSelector(selectContentSection('account')) || {}
  const hasQRCode = has_app && qrcode && !error && loading === 'idle'
  const src = qrcode
  const alt = 'Earn & Redeem In-store'
  const title = alt
  const alert = 'Only relevant for IN-STORE transactions'
  const isLoyalty = true
  // const footnote = "This is NOT required if you're paying with a credit card"
  const footnote = null

  useEffect(() => {
    if (has_app) dispatch(fetchCustomerQRCode())
  }, [dispatch, has_app])

  const showQrCode = () => {
    const args = { src, alt, title, alert, footnote, isLoyalty }
    dispatch(openModal({ type: 'qrCode', args }))
  }

  return hasQRCode ? (
    <AccountScanView>
      <Button size="small" color="secondary" onClick={showQrCode}>
        <AccountScanIcon>
          <Grid size={16} />
        </AccountScanIcon>
        <AccountScanText>Scan In-store</AccountScanText>
      </Button>
    </AccountScanView>
  ) : showLogo ? (
    <HeaderLogo />
  ) : null
}

export default AccountScan
