import { roundPoints } from '@open-tender/utils'
import Heading from 'components/Heading'
import { Star } from 'components/icons'
import {
  PointsShopBalancePoints,
  PointsShopBalanceView,
} from './PointsShop.styled'
import Body from 'components/Body'
import Loading from 'components/Loading'

const PointsShopBalance = ({
  pointsBalance,
  isLoading,
}: {
  pointsBalance: number | null
  isLoading: boolean
}) => {
  if (pointsBalance === null) return null
  return (
    <PointsShopBalanceView>
      {isLoading ? (
        <Loading />
      ) : (
        <Body size="small">Current Points Balance</Body>
      )}
      <PointsShopBalancePoints>
        <Star size={36} strokeWidth={1.5} />
        <Heading size="xxBig">{roundPoints(pointsBalance)}</Heading>
      </PointsShopBalancePoints>
    </PointsShopBalanceView>
  )
}

export default PointsShopBalance
