import styled from '@emotion/styled'
import { CartItemGroups, CartItemOptions, CartLevels } from '@open-tender/types'
import MenuItemOptionSimpleOption from './MenuItemOptionSimpleOption'
import Message from 'components/Message'

const MenuItemOptionSimpleGroupsView = styled.div`
  label: MenuItemOptionSimpleGroups;
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: auto;
    left: 2rem;
    bottom: 2rem;
    justify-content: space-between;
  }
`

const MenuItemOptionSimpleGroupsGroup = styled.div<{ belowMin: boolean }>`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const MenuItemOptionSimpleGroups = ({
  groups,
  levels,
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
  isChild,
}: {
  groups: CartItemGroups
  levels: CartLevels
  toggleOption: (levels: CartLevels) => void
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  setOptionQuantity: (
    levels: CartLevels,
    quantity: number,
    nested?: CartItemOptions
  ) => void
  scrollContainer?: HTMLDivElement | null
  topOffset?: number | null
  headerHeight?: number
  isChild?: boolean
}) => {
  return (
    <MenuItemOptionSimpleGroupsView>
      {groups.map((group) => {
        const { min, quantity } = group
        const belowMin = quantity < min
        return (
          <>
            {belowMin && (
              <Message color="error" style={{ padding: '0.5rem 1.0rem' }}>
                Required
              </Message>
            )}
            <MenuItemOptionSimpleGroupsGroup belowMin={belowMin}>
              {group.options.map((option) => (
                <MenuItemOptionSimpleOption
                  key={`${group.id}-${option.id}`}
                  group={group}
                  option={option}
                  levels={levels}
                  toggleOption={toggleOption}
                  incrementOption={incrementOption}
                  decrementOption={decrementOption}
                  setOptionQuantity={setOptionQuantity}
                />
              ))}
            </MenuItemOptionSimpleGroupsGroup>
          </>
        )
      })}
    </MenuItemOptionSimpleGroupsView>
  )
}

export default MenuItemOptionSimpleGroups
