import styled from '@emotion/styled'
import { ThemeOverlay } from '@open-tender/types'
import { AlertCircle } from 'components/icons'

const MenuItemOptionCardOverlayView = styled.div<{
  overlay: keyof ThemeOverlay
}>`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  background-color: ${(props) => props.theme.overlay[props.overlay]};

  span {
    line-height: 1;
    color: ${(props) => props.theme.colors.light};
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`

const MenuItemOptionCardOverlayAlert = styled.div`
  color: ${(props) => props.theme.colors.light};
`

const MenuItemOptionCardOverlay = () => (
  <MenuItemOptionCardOverlayView overlay="alert">
    <MenuItemOptionCardOverlayAlert>
      <AlertCircle size={22} />
    </MenuItemOptionCardOverlayAlert>
  </MenuItemOptionCardOverlayView>
)

export default MenuItemOptionCardOverlay
