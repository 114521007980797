import styled from '@emotion/styled'
import { removeMessage, useAppDispatch } from '@open-tender/cloud'
import { X } from '../icons'
import { useEffect } from 'react'

const AlertView = styled.li`
  display: block;
  float: right;
  clear: right;
  margin: 1.3rem 0 0;
  line-height: 1;
  padding: 0.8rem 1rem 0.8rem 1.6rem;
  transition: all 500ms ease;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: ${(props) => props.theme.border.radiusSmall};
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) => props.theme.alert.color};
  background-color: ${(props) => props.theme.alert.bgColor};

  > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 0.9;

    span,
    button {
      position: relative;
      display: block;
      flex-shrink: 0;
      color: ${(props) => props.theme.alert.color};
    }

    span {
      top: -0.1rem;
    }

    button {
      top: 0.1rem;
      margin-left: 0.8rem;
    }
  }
`

const Alert = ({ message, id }: { message?: string; id?: string }) => {
  const dispatch = useAppDispatch()

  const handleRemove = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    dispatch(removeMessage(id))
  }
  useEffect(() => {
    setTimeout(() => {
      dispatch(removeMessage(id))
    }, 5000)
  }, [dispatch, id])

  return (
    <AlertView>
      <span>
        <span>{message}</span>
        <button onClick={handleRemove} aria-label="Remove alert">
          <X size={14} />
        </button>
      </span>
    </AlertView>
  )
}

export default Alert
