import {
  MenuItemCalsText,
  MenuItemPriceCals,
  MenuItemPriceCalsSimple,
  MenuItemPriceSep,
  MenuItemPriceText,
} from './MenuItemPrice.styled'

const MenuItemPrice = ({
  price,
  cals,
  isSimple,
}: {
  price: string
  cals: string
  isSimple?: boolean
}) => {
  const showPrice = price && price !== '$0.00'

  if (!showPrice) return null

  const content = (
    <>
      {price ? <MenuItemPriceText>{price}</MenuItemPriceText> : null}
      {price && cals ? <MenuItemPriceSep /> : null}
      {cals ? <MenuItemCalsText>{cals} Cal</MenuItemCalsText> : null}
    </>
  )

  return isSimple ? (
    <MenuItemPriceCalsSimple>{content}</MenuItemPriceCalsSimple>
  ) : (
    <MenuItemPriceCals>{content}</MenuItemPriceCals>
  )
}

export default MenuItemPrice
