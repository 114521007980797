import styled from '@emotion/styled'
import { Body, Headline } from 'components'

const PageTitleView = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.125s forwards;
  text-align: center;
  max-width: ${(props) => props.theme.layout.maxWidth};
  margin: 0 auto ${(props) => props.theme.layout.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0 auto ${(props) => props.theme.layout.marginMobile};
    text-align: left;
  }
`

const PageTitleTitle = styled(Headline)`
  margin: 0 0 0 -0.2rem;
  font-size: ${(props) => props.theme.fonts.sizes.h1};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.h1};
  }
`

export const PageTitleSubtitle = styled(Body)`
  margin: 1rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.main};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0.5rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizesMobile.main};
  }
`

const PageTitle = ({
  title,
  subtitle,
  children,
  style = undefined,
}: {
  title?: string
  subtitle?: string | JSX.Element
  style?: React.CSSProperties
  children?: React.ReactNode
}) => {
  return (
    <PageTitleView style={style}>
      {title && <PageTitleTitle as="h1">{title}</PageTitleTitle>}
      {subtitle && <PageTitleSubtitle as="p">{subtitle}</PageTitleSubtitle>}
      {children}
    </PageTitleView>
  )
}

export default PageTitle
