import styled from '@emotion/styled'
import { LoyaltyProgram as LoyaltyProgramType } from '@open-tender/types'
import { LoyaltyPoints, LoyaltySpend, LoyaltyTiers, LoyaltyVisits } from '.'

const LoyaltyProgramView = styled.div`
  margin-bottom: ${(props) => props.theme.layout.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: ${(props) => props.theme.layout.marginMobile};
  }
`

const LoyaltyProgram = ({
  program,
  isLoading = false,
}: {
  program: LoyaltyProgramType
  isLoading?: boolean
}) => {
  const { loyalty_type, perk, status, spend_name } = program || {}

  if (isLoading && !program) return null

  return (
    <LoyaltyProgramView>
      <>
        {loyalty_type === 'POINTS' ? (
          <LoyaltyPoints program={program} perk={perk} showThresholds={true} />
        ) : loyalty_type === 'DOLLARS' ? (
          <LoyaltySpend program={program} perk={perk} />
        ) : loyalty_type === 'ORDERS' ? (
          <LoyaltyVisits program={program} perk={perk} />
        ) : null}
      </>
      {status && status.tiers && (
        <LoyaltyTiers
          loyaltyType={loyalty_type}
          status={status}
          tiers={status.tiers}
          spendName={spend_name}
        />
      )}
    </LoyaltyProgramView>
  )
}

export default LoyaltyProgram
