import { useNavigate } from 'react-router-dom'
import { ISOString, Order } from '@open-tender/types'
import {
  timezoneMap,
  isoToDateStr,
  isoToDate,
  makeOrderAddress,
  makeOrderTypeName,
} from '@open-tender/utils'
import {
  setOrderServiceType,
  setAddress,
  reorderPastOrder,
  editOrder,
  useAppDispatch,
  useAppSelector,
  selectBrand,
} from '@open-tender/cloud'
import { Button, Card, DeliveryLink, OrderImages, OrderTag } from 'components'
import { ExternalLink } from 'components/icons'
import styled from '@emotion/styled'

const OrderCardTag = styled.div`
  position: absolute;
  right: 1.5rem;
  top: -1.1rem;
`

const OrderCard = ({ order, isLast }: { order: Order; isLast?: boolean }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { tpls } = useAppSelector(selectBrand) || {}
  const {
    order_id,
    status,
    service_type,
    order_type,
    revenue_center,
    requested_at,
    timezone,
    cart,
    address,
    totals,
    delivery,
    order_prep,
  } = order
  const { is_in_prep_queue = false } = order_prep || {}
  const isMerch = order_type === 'MERCH'
  const orderTypeName = makeOrderTypeName(order_type, service_type)
  const tz = timezoneMap[timezone]
  const requestedAt = isoToDateStr(
    requested_at as ISOString,
    tz,
    'MMMM d, yyyy @ h:mma'
  )
  const isUpcoming = isoToDate(requested_at as ISOString) > new Date()
  const streetAddress = makeOrderAddress(address)
  const trackingUrl = delivery && delivery.tracking_url
  const itemImages = cart
    .map((i) =>
      i.images
        .filter((m) => m.type === 'SMALL_IMAGE' && m.url)
        .map((image) => ({ title: i.name, imageUrl: image.url }))
    )
    .flat()
  const itemNames = cart.map((i) => i.name).join(', ')
  const isSaved = status === 'HELD'
  const isEditable = order.is_editable && !tpls
  const isReorderable = !isMerch && !isSaved && service_type !== 'WALKIN'

  const handleReorder = () => {
    const { revenue_center_id: revenueCenterId } = revenue_center
    const serviceType = service_type
    dispatch(
      setOrderServiceType({ orderType: order_type, serviceType: service_type })
    )
    dispatch(setAddress(address || null))
    dispatch(
      reorderPastOrder({ revenueCenterId, serviceType, items: cart as any })
    )
  }

  return (
    <Card
      tag={
        <OrderCardTag>
          <OrderTag
            isUpcoming={isUpcoming}
            status={status}
            inQueue={is_in_prep_queue}
          />
        </OrderCardTag>
      }
      preface={isLast ? 'Your Last Order' : `Order #${order_id}`}
      title={`${orderTypeName} from ${revenue_center.name}`}
      subtitle={
        <>
          {isUpcoming && trackingUrl && (
            <DeliveryLink
              text="Track your delivery"
              trackingUrl={trackingUrl}
              newWindowIcon={<ExternalLink />}
            />
          )}
        </>
      }
      content={
        <>
          <p>
            {requestedAt} &nbsp;|&nbsp; ${totals.total}
          </p>
          <p>{streetAddress}</p>
          <OrderImages images={itemImages} names={itemNames} />
        </>
      }
      footer={
        <>
          {isEditable && (
            <Button onClick={() => dispatch(editOrder(order))} size="small">
              Edit
            </Button>
          )}
          {isReorderable && (
            <Button
              onClick={handleReorder}
              size="small"
              color={order.is_editable ? 'secondary' : 'primary'}
            >
              Reorder
            </Button>
          )}
          <Button
            onClick={() => navigate(`/orders/${order_id}`)}
            size="small"
            color="secondary"
          >
            Details
          </Button>
        </>
      }
    />
  )
}

export default OrderCard
