import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import {
  openModal,
  resetCheckout,
  resetGroupOrder,
  resetOrderType,
  selectGroupOrder,
  selectOrder,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import Button from 'components/Button'
import { Users, XCircle } from 'components/icons'

const MenuGroupOrderView = styled.div`
  padding: ${(props) => props.theme.layout.paddingMobile};
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
`

const MenuGroupOrder = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { cartGuest, cartId } = useAppSelector(selectGroupOrder)
  const { revenueCenter } = useAppSelector(selectOrder)
  const showGroupOrdering = revenueCenter
    ? revenueCenter.group_ordering &&
      revenueCenter.revenue_center_type === 'OLO' &&
      isMobile
    : false

  if (!showGroupOrdering) return null

  const review = () => {
    const reviewOrders = () => navigate(`/review`)
    dispatch(openModal({ type: 'groupOrder', args: { reviewOrders } }))
  }

  const leave = () => {
    dispatch(resetOrderType())
    dispatch(resetGroupOrder())
    dispatch(resetCheckout())
    navigate(`/account`)
  }

  return (
    <MenuGroupOrderView>
      {cartGuest ? (
        <Button onClick={leave} size="small" icon={<XCircle size={16} />}>
          Leave Group Order
        </Button>
      ) : (
        <Button onClick={review} size="small" icon={<Users size={16} />}>
          {cartId ? 'Review your' : 'Start a'} Group Order
        </Button>
      )}
    </MenuGroupOrderView>
  )
}

export default MenuGroupOrder
