import styled from '@emotion/styled'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const MenuItemOptionPriceView = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const MenuItemOptionPricePrice = styled(Heading)`
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.price.fontSize};
  color: ${(props) => props.theme.modifiers.price.color};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0;
  }

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.price.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.price.selectedColor};
  }
`

export const MenuItemOptionPriceSep = styled.span`
  display: block;
  width: 8px;
  height: 1px;
  margin: 0 3px;
  transition: ${(props) => props.theme.modifiers.box.transition};
  background-color: ${(props) => props.theme.modifiers.text.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.text.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.text.selectedColor};
  }
`

export const MenuItemOptionPriceCals = styled(Body)`
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.calories.fontSize};
  color: ${(props) => props.theme.modifiers.calories.color};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0;
  }

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.calories.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.calories.selectedColor};
  }
`
