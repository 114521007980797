import { useState } from 'react'
import styled from '@emotion/styled'
import {
  CartItemGroup,
  CartItemOption,
  CartItemOptions,
  CartLevels,
} from '@open-tender/types'
import { formatDollars, useModifier } from '@open-tender/utils'
import {
  selectDisplaySettings,
  selectSelectedAllergenNames,
  useAppSelector,
} from '@open-tender/cloud'
import {
  BgImage,
  Body,
  ButtonLink,
  CardModifier,
  Collapsible,
  MenuItemIngredients,
  MenuItemNutrition,
  MenuItemTagsAllergens,
  Quantity,
} from 'components'
import MenuItemRadio from '../MenuItemRadio'
import MenuItemPizza from '../MenuItemPizza'
import {
  MenuItemOptionAlert,
  MenuItemOptionSoldOut,
} from '../MenuItemOptionAlerts'
import MenuItemGroups from '.'
import MenuItemOptionPrice from '../MenuItemOptionPrice'
import MenuItemOptionAmount from './MenuItemOptionAmount'

const MenuItemOptionView = styled.div<{ showGroups?: boolean }>`
  width: 100%;
  position: relative;
`

const MenuItemOptionContainer = styled.div<{
  isFirst?: boolean
  showGroups?: boolean
}>`
  width: 100%;
  border-top-style: solid;
  border-top-color: ${(props) => props.theme.border.color};
  border-top-width: ${(props) =>
    props.isFirst ? '0' : props.theme.border.width};
  padding: ${(props) => props.theme.modifiers.box.padding};
`

const MenuItemOptionImage = styled(BgImage)`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  width: ${(props) => props.theme.modifiers.image.width};
  min-height: ${(props) => props.theme.modifiers.image.height};
  padding: ${(props) => props.theme.modifiers.image.padding};
  margin: ${(props) => props.theme.modifiers.image.margin};
  border-radius: ${(props) => props.theme.modifiers.image.borderRadius};
  border-style: solid;
  border-width: ${(props) => props.theme.modifiers.image.border};
  border-color: ${(props) => props.theme.modifiers.image.borderColor};
  background-color: ${(props) => props.theme.modifiers.image.bgColor};
  background-size: ${(props) => props.theme.modifiers.image.bgSize};
  box-shadow: ${(props) => props.theme.modifiers.image.boxShadow};
`

const MenuItemOptionText = styled.div<{ showImage?: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${(props) => props.theme.modifiers.content.padding};
`

const MenuItemOptionName = styled(Body)`
  margin: 0;
  font-size: ${(props) => props.theme.modifiers.title.fontSize};
  color: ${(props) => props.theme.modifiers.title.color};
`

const MenuItemOptionDescription = styled(Body)`
  margin: 5px 0 0;
  font-size: ${(props) => props.theme.modifiers.text.fontSize};
  color: ${(props) => props.theme.modifiers.text.color};
`

const MenuItemOptionQuantity = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 9.2rem;
  justify-content: center;
  text-align: center;
`

const MenuItemOptionSoldOutMessage = styled(Body)`
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  color: ${(props) => props.theme.colors.error};
`

const MenuItemOptionButtons = styled.div`
  margin-top: 4px;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizes.xxSmall};
  }
`

const MenuItemSpacer = styled.div`
  width: 100%;
  height: 2.5rem;
`

const MenuItemOption = ({
  index,
  group,
  option,
  levels = [],
  toggleOption,
  incrementOption,
  decrementOption,
  setOptionQuantity,
}: {
  group: CartItemGroup
  option: CartItemOption
  levels: CartLevels
  toggleOption: (levels: CartLevels) => void
  incrementOption: (levels: CartLevels) => void
  decrementOption: (levels: CartLevels) => void
  setOptionQuantity: (
    levels: CartLevels,
    quantity: number,
    nested?: CartItemOptions
  ) => void
  index?: number
}) => {
  const allergenAlerts = useAppSelector(selectSelectedAllergenNames)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const {
    name,
    description,
    isSoldOut,
    price,
    totalPrice,
    cals,
    tags,
    allergens,
    optionGroups,
    showImage,
    showDescription,
    hasCals,
    hasIngredients,
    allergenAlert,
    bgStyle,
    isCheckbox,
    isRadio,
    showGroups,
    toggle,
    adjust,
    increment,
    decrement,
    incrementDisabled,
    decrementDisabled,
    updatedLevels,
  } = useModifier(
    group,
    option,
    levels,
    toggleOption,
    incrementOption,
    decrementOption,
    setOptionQuantity,
    allergenAlerts,
    displaySettings
  )

  const [showInfo, setShowInfo] = useState(false)
  const [showIngredients, setShowIngredients] = useState(false)
  const [spacer, setSpacer] = useState(false)
  const isAmount =
    optionGroups &&
    optionGroups.length === 1 &&
    optionGroups[0].name.toLowerCase() === 'amount'

  const toggleShowInfo = () => {
    if (showIngredients) setShowIngredients(false)
    setShowInfo(!showInfo)
  }

  const toggleShowIngredients = () => {
    if (showInfo) setShowInfo(false)
    setShowIngredients(!showIngredients)
  }

  return (
    <MenuItemOptionView showGroups={showGroups}>
      <MenuItemOptionContainer isFirst={index === 0} showGroups={showGroups}>
        <CardModifier>
          {showImage && (
            <MenuItemOptionImage style={bgStyle || undefined}>
              {isSoldOut ? (
                <MenuItemOptionSoldOut />
              ) : allergenAlert ? (
                <MenuItemOptionAlert />
              ) : null}
            </MenuItemOptionImage>
          )}
          <MenuItemOptionText showImage={showImage}>
            <MenuItemOptionName as="p">{name}</MenuItemOptionName>
            {showDescription && description ? (
              <MenuItemOptionDescription as="p">
                {description}
              </MenuItemOptionDescription>
            ) : null}
            <MenuItemOptionPrice
              price={
                totalPrice
                  ? formatDollars(totalPrice.toString())
                  : price === null
                  ? '$0.00'
                  : price
              }
              cals={cals}
              style={{ marginTop: '0.5rem' }}
            />
            <MenuItemTagsAllergens
              tags={tags}
              allergens={allergens}
              style={{ marginTop: '0.5rem' }}
            />
            {hasCals || hasIngredients ? (
              <MenuItemOptionButtons>
                {hasCals ? (
                  <ButtonLink onClick={toggleShowInfo}>
                    {!showInfo ? 'show' : 'hide'} nutritional info
                  </ButtonLink>
                ) : null}
                {hasCals && hasIngredients ? (
                  <Body size="xSmall">{' | '}</Body>
                ) : null}
                {hasIngredients ? (
                  <ButtonLink onClick={toggleShowIngredients}>
                    {!showIngredients ? 'show' : 'hide'} ingredients
                  </ButtonLink>
                ) : null}
              </MenuItemOptionButtons>
            ) : null}
          </MenuItemOptionText>
          <MenuItemOptionQuantity>
            {isSoldOut ? (
              <MenuItemOptionSoldOutMessage>
                Out of stock
              </MenuItemOptionSoldOutMessage>
            ) : optionGroups && group.isPizza ? (
              <MenuItemPizza
                option={option}
                levels={updatedLevels}
                increment={increment}
                decrement={decrement}
                adjust={adjust}
                incrementDisabled={incrementDisabled}
                decrementDisabled={decrementDisabled}
                toggleOption={toggleOption}
                incrementOption={incrementOption}
                decrementOption={decrementOption}
                setSpacer={setSpacer}
              />
            ) : isRadio ? (
              <MenuItemRadio option={option} handler={toggle} />
            ) : (
              <Quantity
                item={option}
                increment={increment}
                decrement={decrement}
                adjust={adjust}
                incrementDisabled={incrementDisabled}
                decrementDisabled={decrementDisabled}
                showAdd={true}
                isCheckbox={isCheckbox}
              />
            )}
            {isAmount ? (
              <MenuItemOptionAmount
                option={option}
                levels={updatedLevels}
                incrementDisabled={incrementDisabled}
                toggleOption={toggleOption}
                setSpacer={setSpacer}
              />
            ) : null}
          </MenuItemOptionQuantity>
        </CardModifier>
      </MenuItemOptionContainer>
      {spacer ? (
        <Collapsible show={spacer}>
          <MenuItemSpacer />
        </Collapsible>
      ) : null}
      {hasCals ? (
        <Collapsible show={showInfo}>
          <MenuItemNutrition nutritionalInfo={option.nutritionalInfo} />
        </Collapsible>
      ) : null}
      {hasIngredients ? (
        <Collapsible show={showIngredients}>
          <MenuItemIngredients ingredients={option.ingredients} />
        </Collapsible>
      ) : null}
      {optionGroups && !group.isPizza && !isAmount ? (
        <Collapsible show={showGroups}>
          <MenuItemGroups
            groups={option.groups}
            levels={updatedLevels}
            toggleOption={toggleOption}
            incrementOption={incrementOption}
            decrementOption={decrementOption}
            setOptionQuantity={setOptionQuantity}
            isChild={true}
          />
        </Collapsible>
      ) : null}
    </MenuItemOptionView>
  )
}

export default MenuItemOption
