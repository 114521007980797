import styled from '@emotion/styled'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const MenuItemButtonView = styled.button<{
  disabled?: boolean
}>`
  label: MenuItemButtonView;
  flex-grow: 1;
  display: block;
  text-align: left;
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transition: ${(props) => props.theme.menuItems.box.transition};

  &:disabled {
    opacity: 1;
  }
`

export const MenuItemButtonContainer = styled.div<{
  flexDirection: string
  alignItems: string
}>`
  height: 100%;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems};
`

export const MenuItemButtonImage = styled.div<{ isVertical: boolean }>`
  display: flex;
  overflow: hidden;
  align-self: flex-start;
  aspect-ratio: ${(props) =>
    props.theme.menuItems.image.asImg
      ? 'auto'
      : props.theme.menuItems.image.aspectRatio};
  flex-grow: 0;
  flex-shrink: 0;
  width: ${(props) =>
    props.isVertical ? '100%' : props.theme.menuItems.image.minWidth};
  padding: ${(props) => props.theme.menuItems.image.padding};
  border-style: solid;
  border-width: ${(props) => props.theme.menuItems.image.border};
  border-radius: ${(props) => props.theme.menuItems.image.borderRadius};
  margin: ${(props) => props.theme.menuItems.image.margin};
  border-color: ${(props) => props.theme.menuItems.image.borderColor};
  box-shadow: ${(props) => props.theme.menuItems.image.boxShadow};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: ${(props) =>
      props.isVertical ? '100%' : props.theme.menuItems.image.minWidthMobile};
    padding: ${(props) => props.theme.menuItems.image.paddingMobile};
    border-width: ${(props) => props.theme.menuItems.image.borderMobile};
    border-radius: ${(props) => props.theme.menuItems.image.borderRadiusMobile};
    margin: ${(props) => props.theme.menuItems.image.marginMobile};
  }
`

export const MenuItemButtonContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: ${(props) => props.theme.menuItems.content.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.menuItems.content.paddingMobile};
  }
`
export const MenuItemButtonInfoView = styled.div``

export const MenuItemButtonTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const MenuItemButtonName = styled(Heading)`
  display: block;
  flex: 1 1 auto;
  transition: ${(props) => props.theme.menuItems.box.transition};
  color: ${(props) => props.theme.menuItems.title.color};
  font-size: ${(props) => props.theme.menuItems.title.fontSize};
  padding: ${(props) => props.theme.menuItems.title.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.menuItems.title.fontSizeMobile};
    padding: ${(props) => props.theme.menuItems.title.paddingMobile};
  }

  .show-hover:hover & {
    color: ${(props) => props.theme.menuItems.title.colorHover};
  }
`

export const MenuItemButtonDescription = styled(Body)`
  display: block;
  transition: ${(props) => props.theme.menuItems.box.transition};
  color: ${(props) => props.theme.menuItems.text.color};
  font-size: ${(props) => props.theme.menuItems.text.fontSize};
  padding: ${(props) => props.theme.menuItems.text.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.menuItems.text.fontSizeMobile};
    padding: ${(props) => props.theme.menuItems.text.paddingMobile};
  }

  .show-hover:hover & {
    color: ${(props) => props.theme.menuItems.text.colorHover};
  }
`

export const MenuItemButtonTagsAllergens = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.theme.menuItems.tagsAllergens.flexDirection};
  justify-content: ${(props) =>
    props.theme.menuItems.tagsAllergens.justifyContent};
  align-items: center;
  flex-wrap: wrap;
  padding: ${(props) => props.theme.menuItems.tagsAllergens.padding};
  gap: ${(props) => props.theme.menuItems.tagsAllergens.gap};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.menuItems.tagsAllergens.paddingMobile};
    gap: ${(props) => props.theme.menuItems.tagsAllergens.gapMobile};
  }

  & > span {
    display: block;
  }
`
