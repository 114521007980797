const Logo = ({ width = undefined }) => {
  return (
    <svg viewBox="0 0 854 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M820.873 119.444C814.33 119.444 808.542 118.06 803.509 115.292C798.476 112.523 794.533 108.665 791.681 103.715C788.829 98.7662 787.403 93.02 787.403 86.477C787.403 79.9339 788.829 74.1877 791.681 69.2384C794.533 64.2892 798.476 60.4304 803.509 57.6622C808.626 54.8939 814.498 53.5098 821.125 53.5098C827.081 53.5098 832.45 54.9359 837.231 57.788C842.013 60.6401 845.787 64.9183 848.556 70.6225C851.408 76.2429 852.834 83.2054 852.834 91.5101H805.522L807.661 89.1194C807.661 91.9715 808.249 94.488 809.423 96.6691C810.597 98.8501 812.233 100.528 814.33 101.702C816.427 102.793 818.776 103.338 821.377 103.338C824.48 103.338 827.081 102.667 829.178 101.325C831.275 99.8987 832.743 98.1371 833.582 96.0399L851.072 101.954C849.395 105.98 847.004 109.294 843.9 111.894C840.88 114.495 837.399 116.424 833.456 117.682C829.514 118.857 825.319 119.444 820.873 119.444ZM808.165 81.0663L806.151 78.4239H834.337L832.45 81.0663C832.45 77.8787 831.82 75.404 830.562 73.6424C829.304 71.7969 827.752 70.4548 825.907 69.6159C824.145 68.777 822.341 68.3576 820.496 68.3576C818.65 68.3576 816.763 68.777 814.834 69.6159C812.988 70.4548 811.394 71.7969 810.052 73.6424C808.794 75.404 808.165 77.8787 808.165 81.0663Z"
        fill="currentColor"
      />
      <path
        d="M769.006 119.29C761.54 119.29 755.962 117.528 752.271 114.005C748.664 110.398 746.86 105.281 746.86 98.6538V35.2361H766.49V93.7465C766.49 96.263 766.951 98.1085 767.874 99.2829C768.796 100.457 770.223 101.045 772.152 101.045C772.907 101.045 773.788 100.877 774.794 100.541C775.801 100.122 776.724 99.6185 777.563 99.0313L784.357 113.376C782.344 115.053 779.911 116.48 777.059 117.654C774.291 118.744 771.607 119.29 769.006 119.29ZM737.046 71.3489V54.8654H780.834V71.3489H737.046Z"
        fill="currentColor"
      />
      <path
        d="M712.332 117.934L711.703 104.345V86.6028C711.703 83.2473 711.409 80.3113 710.822 77.7948C710.319 75.2782 709.27 73.3488 707.676 72.0066C706.166 70.5806 703.943 69.8676 701.007 69.8676C698.658 69.8676 696.603 70.4128 694.842 71.5033C693.08 72.5938 691.57 74.0618 690.312 75.9073L673.073 70.245C674.583 67.1413 676.554 64.3311 678.987 61.8145C681.504 59.2979 684.566 57.2847 688.173 55.7747C691.78 54.2648 695.974 53.5098 700.756 53.5098C707.299 53.5098 712.835 54.7262 717.365 57.1589C721.895 59.5915 725.292 62.9889 727.557 67.351C729.822 71.713 730.954 76.872 730.954 82.8279L730.703 117.934H712.332ZM695.093 119.444C687.711 119.444 681.965 117.808 677.855 114.537C673.744 111.181 671.689 106.4 671.689 100.192C671.689 93.5653 673.828 88.616 678.106 85.3445C682.385 82.0729 688.382 80.4372 696.1 80.4372H713.338V93.1459H703.398C699.287 93.1459 696.31 93.7331 694.464 94.9075C692.619 95.998 691.696 97.5918 691.696 99.689C691.696 101.199 692.367 102.415 693.709 103.338C695.135 104.177 697.065 104.596 699.497 104.596C701.93 104.596 704.027 104.093 705.789 103.086C707.634 101.996 709.06 100.57 710.067 98.8082C711.157 97.0466 711.703 95.1591 711.703 93.1459H716.736C716.736 101.451 714.932 107.91 711.325 112.523C707.802 117.137 702.391 119.444 695.093 119.444Z"
        fill="currentColor"
      />
      <path
        d="M566.694 117.934V55.0198H585.191L585.946 62.947C587.959 59.8432 590.434 57.4944 593.37 55.9006C596.389 54.3067 599.703 53.5098 603.31 53.5098C608.511 53.5098 612.789 54.6003 616.145 56.7814C619.584 58.8785 622.1 62.1081 623.694 66.4702C625.624 62.2759 628.266 59.0882 631.621 56.9072C634.977 54.6423 638.752 53.5098 642.946 53.5098C650.496 53.5098 656.158 55.8167 659.933 60.4304C663.792 65.0441 665.679 72.2164 665.595 81.9471V117.934H645.966V85.722C645.966 82.3665 645.588 79.7241 644.833 77.7948C644.162 75.7815 643.156 74.3135 641.814 73.3908C640.471 72.468 638.878 71.9647 637.032 71.8808C633.425 71.7969 630.657 72.8455 628.727 75.0265C626.882 77.1237 625.959 80.3533 625.959 84.7153V117.934H606.33V85.722C606.33 82.3665 605.952 79.7241 605.197 77.7948C604.526 75.7815 603.52 74.3135 602.178 73.3908C600.835 72.468 599.242 71.9647 597.396 71.8808C593.789 71.7969 591.021 72.8455 589.091 75.0265C587.246 77.1237 586.323 80.3533 586.323 84.7153V117.934H566.694Z"
        fill="currentColor"
      />
      <path
        d="M515.933 104.019L504.357 90.4299L535.94 55.072H558.967L515.933 104.019ZM497.311 117.986V29.9063H516.94V117.986H497.311ZM537.828 117.986L518.324 90.1782L531.159 76.7146L560.603 117.986H537.828Z"
        fill="currentColor"
      />
      <path
        d="M461.901 119.444C455.191 119.444 449.193 118.06 443.908 115.292C438.707 112.44 434.639 108.539 431.703 103.59C428.767 98.5565 427.299 92.8523 427.299 86.477C427.299 80.0177 428.767 74.3135 431.703 69.3642C434.639 64.415 438.665 60.5562 443.782 57.788C448.983 54.9359 454.939 53.5098 461.65 53.5098C468.109 53.5098 474.023 55.1456 479.392 58.4171C484.76 61.6887 488.619 66.4282 490.968 72.6358L472.345 78.9272C471.422 76.914 469.871 75.2782 467.69 74.0199C465.592 72.6777 463.244 72.0066 460.643 72.0066C458.043 72.0066 455.736 72.6358 453.723 73.8941C451.793 75.0685 450.241 76.7462 449.067 78.9272C447.976 81.1083 447.431 83.6248 447.431 86.477C447.431 89.3291 447.976 91.8456 449.067 94.0267C450.241 96.1238 451.835 97.8015 453.848 99.0598C455.946 100.318 458.294 100.947 460.895 100.947C463.495 100.947 465.844 100.276 467.941 98.934C470.038 97.5918 471.59 95.8302 472.597 93.6492L491.22 99.9406C488.787 106.232 484.886 111.055 479.517 114.411C474.233 117.766 468.361 119.444 461.901 119.444Z"
        fill="currentColor"
      />
      <path
        d="M389.247 119.444C382.704 119.444 376.916 118.06 371.882 115.292C366.849 112.523 362.907 108.665 360.054 103.715C357.202 98.7662 355.776 93.02 355.776 86.477C355.776 79.9339 357.202 74.1877 360.054 69.2384C362.907 64.2892 366.849 60.4304 371.882 57.6622C376.999 54.8939 382.871 53.5098 389.498 53.5098C395.454 53.5098 400.823 54.9359 405.604 57.788C410.386 60.6401 414.161 64.9183 416.929 70.6225C419.781 76.2429 421.207 83.2054 421.207 91.5101H373.896L376.035 89.1194C376.035 91.9715 376.622 94.488 377.796 96.6691C378.971 98.8501 380.607 100.528 382.704 101.702C384.801 102.793 387.15 103.338 389.75 103.338C392.854 103.338 395.454 102.667 397.551 101.325C399.649 99.8987 401.117 98.1371 401.955 96.0399L419.446 101.954C417.768 105.98 415.377 109.294 412.273 111.894C409.254 114.495 405.772 116.424 401.83 117.682C397.887 118.857 393.693 119.444 389.247 119.444ZM376.538 81.0663L374.525 78.4239H402.71L400.823 81.0663C400.823 77.8787 400.194 75.404 398.936 73.6424C397.677 71.7969 396.125 70.4548 394.28 69.6159C392.518 68.777 390.715 68.3576 388.869 68.3576C387.024 68.3576 385.136 68.777 383.207 69.6159C381.361 70.4548 379.768 71.7969 378.425 73.6424C377.167 75.404 376.538 77.8787 376.538 81.0663Z"
        fill="currentColor"
      />
      <path
        d="M289.539 117.986V29.9063H309.168V117.986H289.539ZM330.056 117.986V85.7742H349.685V117.986H330.056ZM330.056 85.7742C330.056 82.4188 329.678 79.7764 328.923 77.847C328.168 75.8338 327.078 74.3657 325.652 73.443C324.226 72.5203 322.548 72.0169 320.619 71.9331C316.844 71.8492 313.992 72.8978 312.062 75.0788C310.133 77.1759 309.168 80.4055 309.168 84.7676H303.254C303.254 78.1406 304.261 72.5203 306.274 67.9065C308.287 63.2089 311.014 59.6438 314.453 57.2111C317.976 54.7784 322.003 53.5621 326.533 53.5621C331.65 53.5621 335.928 54.6106 339.367 56.7078C342.806 58.721 345.365 61.8248 347.043 66.0191C348.804 70.2134 349.685 75.5821 349.685 82.1252V85.7742H330.056Z"
        fill="currentColor"
      />
      <path
        d="M242.805 119.483C234.081 119.483 226.363 117.554 219.653 113.695C213.026 109.753 207.783 104.384 203.924 97.5891C200.149 90.7105 198.262 82.8252 198.262 73.9333C198.262 65.0414 200.149 57.1981 203.924 50.4033C207.783 43.5247 213.026 38.156 219.653 34.2972C226.363 30.3546 234.081 28.3833 242.805 28.3833C249.18 28.3833 255.01 29.5158 260.295 31.7807C265.664 33.9617 270.278 37.0655 274.136 41.092C278.079 45.1185 281.099 49.7742 283.196 55.059L264.196 61.4763C262.938 58.5402 261.218 55.9817 259.037 53.8007C256.94 51.6197 254.507 49.942 251.739 48.7676C249.055 47.5932 246.077 47.0059 242.805 47.0059C238.191 47.0059 234.081 48.1384 230.474 50.4033C226.867 52.6682 224.057 55.8559 222.043 59.9663C220.03 63.9928 219.023 68.6485 219.023 73.9333C219.023 79.2181 220.03 83.9157 222.043 88.0261C224.14 92.0526 226.993 95.2403 230.6 97.5891C234.291 99.854 238.485 100.986 243.183 100.986C246.622 100.986 249.684 100.357 252.368 99.099C255.052 97.8407 257.359 96.0792 259.289 93.8142C261.302 91.5493 263.022 88.9489 264.448 86.0129L283.448 92.4301C281.435 97.7988 278.457 102.538 274.514 106.649C270.571 110.675 265.916 113.821 260.547 116.086C255.178 118.351 249.264 119.483 242.805 119.483Z"
        fill="currentColor"
      />
      <path
        d="M162.014 53.2607L114.195 5.44169C106.94 -1.8139 95.177 -1.8139 87.9214 5.44169L66.3764 26.9867L74.4334 35.0437C81.6889 42.2993 93.4517 42.2993 100.707 35.0437L101.058 34.6932L103.685 37.3202C105.136 38.7718 105.136 41.1238 103.685 42.5755L81.8772 64.3831C77.3874 68.2611 73.902 70.5809 68.6777 72.0102C61.982 73.8421 59.1444 72.2468 58.4272 71.9372L74.4322 87.942C81.6877 95.1976 93.4505 95.1976 100.706 87.942L124.878 63.77C126.33 62.3184 128.682 62.3184 130.133 63.77C131.585 65.2216 131.585 67.5736 130.133 69.0253L81.8759 117.283C77.3861 121.161 73.9008 123.481 68.6765 124.91C61.9807 126.742 59.1432 125.146 58.426 124.837L74.4309 140.842C81.6865 148.097 93.4492 148.097 100.705 140.842L162.011 79.5358C169.266 72.2802 169.266 60.5175 162.011 53.2619L162.014 53.2607Z"
        fill="currentColor"
      />
      <path
        d="M80.0123 98.4603C74.788 97.031 71.3027 94.7124 66.8128 90.8331L37.3298 61.3501C35.8781 59.8985 35.8781 57.5464 37.3298 56.0948C38.7814 54.6432 41.1335 54.6432 42.5851 56.0948L47.9828 61.4925C55.2384 68.7481 67.0011 68.7481 74.2567 61.4925L90.2616 45.4876C89.5444 45.7973 86.7056 47.3925 80.0111 45.5607C74.7868 44.1314 71.3015 41.8128 66.8116 37.9335L53.0709 24.1928C45.8116 16.9335 34.0414 16.9384 26.7882 24.2014L5.43272 45.5855C-1.81419 52.8423 -1.8105 64.5989 5.44137 71.8507L47.9828 114.392C55.2384 121.648 67.0011 121.648 74.2567 114.392L90.2616 98.3872C89.5444 98.6969 86.7056 100.292 80.0111 98.4603H80.0123Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Logo
