import { useNavigate } from 'react-router-dom'
import {
  openModal,
  selectCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { UserCircle } from '../icons'
import Icon from './Icon'

const User = ({ to }: { to?: string }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { auth } = useAppSelector(selectCustomer)

  const login = () => {
    dispatch(openModal({ type: 'login' }))
  }

  const goToSettings = () => navigate(to || '/account/settings')

  return (
    <Icon
      aria-label={auth ? 'Account Profile' : 'Log into your account'}
      margin="0 0 0 -1.1rem"
      onClick={auth ? goToSettings : login}
    >
      <UserCircle size={28} isFilled={!!auth} />
    </Icon>
  )
}

export default User
