import { useNavigate } from 'react-router-dom'
import {
  resetOrderType,
  resetGroupOrder,
  resetCheckout,
  useAppDispatch,
} from '@open-tender/cloud'
import { Button } from 'components'
import { LogOut } from 'components/icons'

const LeaveGroup = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const leave = () => {
    dispatch(resetOrderType())
    dispatch(resetGroupOrder())
    dispatch(resetCheckout())
    navigate(`/account`)
  }

  return (
    <Button
      icon={<LogOut size={18} />}
      onClick={leave}
      color="header"
      size="header"
    >
      Leave Group Order
    </Button>
  )
}

export default LeaveGroup
