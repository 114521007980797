import { useEffect } from 'react'
import { ISOString, Order as OrderType } from '@open-tender/types'
import {
  formatDollars,
  isoToDate,
  makeDisplayItem,
  makeOrderTypeName,
  makeSimpleCart,
} from '@open-tender/utils'
import {
  editOrder,
  openModal,
  reorderPastOrder,
  resetCustomerOrder,
  selectBrand,
  selectCustomer,
  selectCustomerFavorites,
  setAddress,
  setOrderServiceType,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  CartSummaryItem,
  CheckSummary,
  FormSection,
  OrderFulfillmentForm,
  Preface,
  Tag,
} from 'components'
import { Favorite } from 'components/buttons'
import OrderAddress from '../OrderAddress'
import OrderRating from './OrderRating'
import OrderRequestedAt from './OrderRequestedAt'
import OrderRevenueCenter from './OrderRevenueCenter'
import OrderSection from './OrderSection'
import OrderFulfillment from './OrderFulfillment'
import OrderPrep from './OrderPrep'
import {
  OrderButtons,
  OrderCurbside,
  OrderDetails,
  OrderFavorite,
  OrderHeader,
  OrderHeaderTag,
  OrderItems,
  OrderMessage,
  OrderTitle,
  OrderView,
} from './Order.styled'
import { Link } from 'react-router-dom'

const Order = ({
  order,
  isConfirmation = false,
  isQuote = false,
}: {
  order: OrderType
  isConfirmation?: boolean
  isQuote?: boolean
}) => {
  const {
    order_id,
    status,
    service_type,
    order_type,
    revenue_center,
    is_asap,
    is_editable,
    requested_at,
    requested_time,
    estimated_at,
    timezone,
    delivery,
    address,
    cart,
    details,
    order_fulfillment,
    rating,
    order_prep,
    deposit,
  } = order || {}
  const dispatch = useAppDispatch()
  const isMerch = order_type === 'MERCH'
  let orderTypeName = makeOrderTypeName(order_type, service_type)
  const displayedItems = cart ? cart.map((i) => makeDisplayItem(i)) : []
  const { lookup = {} } = useAppSelector(selectCustomerFavorites)
  const { auth } = useAppSelector(selectCustomer)
  const {
    tpls,
    order_rating_types = [],
    fulfillment,
    has_ext_curbside = false,
  } = useAppSelector(selectBrand) || {}
  const {
    eating_utensils = false,
    serving_utensils = false,
    person_count = null,
    notes = null,
    notes_internal = null,
    tax_exempt_id = null,
  } = details || {}
  const prepType = order_prep?.prep_type || null
  const isTakeOut =
    (notes_internal && notes_internal.includes('TAKE OUT')) ||
    prepType === 'TAKE_OUT'
  orderTypeName = isTakeOut ? 'Take Out' : orderTypeName
  orderTypeName =
    service_type === 'PICKUP' && order_type !== 'CATERING'
      ? `${order_fulfillment ? 'Curbside' : 'In-store'} ${orderTypeName}`
      : orderTypeName
  const hasDetails =
    eating_utensils || serving_utensils || person_count || tax_exempt_id
  const orderTitle = revenue_center
    ? `${orderTypeName} from ${revenue_center.name}`
    : ''
  const isPending = status === 'PENDING'
  const isSaved = status === 'HELD' && !isQuote
  const isClosed = status === 'CLOSED'
  const isUpcoming =
    requested_at === 'asap' || isoToDate(requested_at) > new Date()
  const isCurbside =
    isUpcoming &&
    fulfillment &&
    revenue_center?.has_order_fulfillment &&
    service_type === 'PICKUP' &&
    !has_ext_curbside &&
    order_prep?.prep_status !== 'FULFILLED'
  const showOrderRating = !!(
    isClosed &&
    order_rating_types.includes(order_type) &&
    !isConfirmation
  )
  const isWalkin = service_type === 'WALKIN'
  const showReorder = !isSaved && !isQuote && !isConfirmation && !isWalkin
  const openQuote = order.is_editable && isQuote
  const closedQuote = !order.is_editable && isQuote && order_type === 'CATERING'
  const showButtons =
    !isMerch && (is_editable || showReorder || showOrderRating)
  const isEditable = is_editable && !tpls

  useEffect(() => {
    return () => {
      dispatch(resetCustomerOrder())
    }
  }, [dispatch])

  if (!revenue_center || !order_id) return null

  const handleReorder = () => {
    const { revenue_center_id: revenueCenterId } = revenue_center
    const serviceType = service_type
    dispatch(
      setOrderServiceType({ orderType: order_type, serviceType: service_type })
    )
    dispatch(setAddress(address || null))
    dispatch(
      reorderPastOrder({
        revenueCenterId,
        serviceType,
        items: makeSimpleCart(cart),
      })
    )
  }

  const updateRating = () => {
    const args = { orderId: order_id, orderRating: rating || {} }
    dispatch(openModal({ type: 'rating', args }))
  }

  return (
    <OrderView>
      <OrderHeader>
        <OrderHeaderTag>
          {isPending && <Tag text="Pending Approval" color="error" />}
          {isSaved && <Tag text="Saved" color="success" />}
        </OrderHeaderTag>
        <Link to={`/orders/${order_id}`}>
          <Preface size="small" color="tertiary">
            {isQuote && !isWalkin ? 'Quote' : 'Order'} #{order_id}
          </Preface>
        </Link>
        <OrderTitle as={isConfirmation ? 'h2' : 'h1'}>{orderTitle}</OrderTitle>
        {isSaved && (
          <OrderMessage size="small" as="p">
            This is a saved order and has not yet been submitted.
          </OrderMessage>
        )}
        {openQuote && (
          <>
            <OrderMessage size="small" as="p">
              This is a quote. Please submit the order with payment to accept.
            </OrderMessage>
            {deposit && (
              <>
                <OrderMessage size="small" as="p">
                  This quote requires a deposit of {formatDollars(deposit)},
                  which must be paid in full in order to accept.
                </OrderMessage>
                <OrderMessage size="small" as="p">
                  The balance is then due on the day or the event.
                </OrderMessage>
              </>
            )}
          </>
        )}
        {closedQuote && (
          <OrderMessage size="small" as="p">
            This quote has expired. Please contact our catering team.
          </OrderMessage>
        )}
        {showButtons && (
          <OrderButtons>
            {isEditable && (
              <Button onClick={() => dispatch(editOrder(order))} size="small">
                {openQuote ? 'Accept Quote & Submit Payment' : 'Edit'}
              </Button>
            )}
            {showReorder && (
              <Button onClick={handleReorder} size="small">
                Reorder
              </Button>
            )}
            {showOrderRating && (
              <Button onClick={updateRating} size="small" color="secondary">
                {rating ? 'Update Rating' : 'Rate Order'}
              </Button>
            )}
          </OrderButtons>
        )}
      </OrderHeader>
      {isCurbside && (
        <OrderCurbside>
          <OrderFulfillmentForm
            orderId={order_id}
            settings={fulfillment}
            isCurbside={!!order_fulfillment}
          />
        </OrderCurbside>
      )}
      {order_prep && !isQuote && !isSaved && (
        <OrderPrep
          orderId={order_id}
          orderPrep={order_prep}
          serviceType={service_type}
        />
      )}
      <OrderDetails>
        <OrderSection label="Location">
          <OrderRevenueCenter revenueCenter={revenue_center} />
        </OrderSection>
        <OrderSection label="Requested Time">
          <OrderRequestedAt
            estimated_at={(estimated_at || requested_at) as ISOString}
            requested_time={requested_time || ''}
            timezone={timezone}
            is_asap={is_asap}
            status={status}
          />
        </OrderSection>
        {service_type === 'DELIVERY' && address && (
          <OrderSection label="Delivery Address">
            <OrderAddress
              address={address}
              delivery={delivery}
              status={status}
            />
          </OrderSection>
        )}
        {order_fulfillment && (
          <OrderSection label="Curbside Pickup">
            <OrderFulfillment {...order_fulfillment} />
          </OrderSection>
        )}
        {notes && notes.length ? (
          <OrderSection label="Notes" noTitle={true}>
            <p>{notes}</p>
          </OrderSection>
        ) : null}
        {hasDetails && (
          <OrderSection label="Other Details" noTitle={true}>
            {eating_utensils ? (
              <p>
                Eating utensils included
                {person_count && ` for ${person_count} people`}
              </p>
            ) : (
              person_count && <p>{person_count} people to be accommodated</p>
            )}
            {serving_utensils ? <p>Serving utensils included</p> : null}
            {tax_exempt_id && <p>Tax exempt ID of {tax_exempt_id}</p>}
          </OrderSection>
        )}
        {rating ? (
          <OrderSection label="Rating">
            <OrderRating {...rating} />
          </OrderSection>
        ) : null}
      </OrderDetails>
      <FormSection title="Order Summary & Receipt">
        {cart?.length > 0 ? (
          <OrderItems>
            {displayedItems.map((item, index) => {
              const show = auth && lookup
              const { signature } = item
              const favoriteId =
                lookup && signature ? lookup[signature] || null : null
              return (
                <CartSummaryItem
                  key={`${item.id}-${index}`}
                  item={item}
                  pointsObj={null}
                >
                  {show && (
                    <OrderFavorite>
                      <Favorite item={item} favoriteId={favoriteId} />
                    </OrderFavorite>
                  )}
                </CartSummaryItem>
              )
            })}
          </OrderItems>
        ) : (
          <></>
        )}
        {order && <CheckSummary check={order} showTenders={true} />}
      </FormSection>
    </OrderView>
  )
}

export default Order
