import styled from '@emotion/styled'
import BgImage from 'components/BgImage'
import Body from 'components/Body'
import Heading from 'components/Heading'

export const MenuItemSizeView = styled.div`
  padding: 2rem ${(props) => props.theme.item.desktop.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem ${(props) => props.theme.item.mobile.padding};
  }
`

export const MenuItemSizeInstructions = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.item.desktop.padding};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-top: ${(props) => props.theme.item.mobile.padding};
  }
`

export const MenuItemSizeInstructionsValue = styled(Body)``

export const MenuItemSizeToggles = styled.div`
  padding: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0;
  }

  button {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

export const MenuItemSizeTogglesSpacer = styled.span`
  padding: 0 1rem;
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

export const MenuItemSizeButtonsHeader = styled.div`
  display: flex;
  margin: 0 0 1rem;
  justify-content: space-between;
  align-items: flex-end;
`

export const MenuItemSizeButtonsHeaderTitle = styled(Heading)`
  margin-bottom: 0.5rem;
  font-size: ${(props) => props.theme.fonts.sizes.big};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizes.main};
  }

  span {
    color: ${(props) => props.theme.colors.error};
  }
`

export const MenuItemSizeButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;
  padding: 0 0 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 0.5rem;
  }
`

export const MenuItemSizeSectionView = styled.div`
  padding: 2rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 2rem 0 0;
  }
`

export const MenuItemSizeButtonView = styled.button<{
  isApplied?: boolean
  disabled?: boolean
}>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${(props) => props.theme.modifiers.box.minHeight};
  padding: 1rem 0.5rem;
  border-radius: ${(props) => props.theme.modifiers.box.borderRadius};
  border-style: solid;
  border-width: ${(props) => props.theme.modifiers.box.border};
  border-color: ${(props) => props.theme.modifiers.colors.borderColor};
  background-color: ${(props) => props.theme.modifiers.colors.bgColor};
  box-shadow: ${(props) => props.theme.modifiers.box.boxShadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-width: ${(props) => props.theme.modifiers.box.borderMobile};
  }

  &:disabled {
    opacity: 1;
  }

  &.show-hover:not(.selected):hover,
  &.show-hover:not(.selected):active {
    border-color: ${(props) => props.theme.modifiers.hoverColors.borderColor};
    background-color: ${(props) => props.theme.modifiers.hoverColors.bgColor};
  }

  &.selected {
    border-color: ${(props) =>
      props.theme.modifiers.selectedColors.borderColor};
    background-color: ${(props) =>
      props.theme.modifiers.selectedColors.bgColor};
  }

  & > span {
    display: block;
    text-align: center;
  }
`

export const MenuItemSizeButtonCheckmark = styled.div`
  position: absolute;
  z-index: 2;
  top: -0.8rem;
  right: -0.8rem;
`

export const MenuItemSizeButtonImage = styled(BgImage)`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 18rem;
  aspect-ratio: 1.5;
  margin: 0 0 1rem;
  padding: ${(props) => props.theme.modifiers.image.padding};
  border-radius: ${(props) => props.theme.modifiers.image.borderRadius};
  border-style: solid;
  border-width: ${(props) => props.theme.modifiers.image.border};
  border-color: ${(props) => props.theme.modifiers.image.borderColor};
  background-color: ${(props) => props.theme.modifiers.image.bgColor};
  background-size: ${(props) => props.theme.modifiers.image.bgSize};
  box-shadow: ${(props) => props.theme.modifiers.image.boxShadow};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-grow: 0;
    flex-shrink: 0;
    width: ${(props) => props.theme.modifiers.image.width};
    min-height: ${(props) => props.theme.modifiers.image.height};
    aspect-ratio: auto;
    margin: ${(props) => props.theme.modifiers.image.margin};
  }
`

export const MenuItemSizeButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const MenuItemSizeButtonInfo = styled.div`
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left;
  }

  & > span {
    display: block;
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      text-align: left;
    }
  }
`

export const MenuItemSizeButtonTitle = styled(Heading)<{ isApplied?: boolean }>`
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.title.fontSize};
  color: ${(props) => props.theme.modifiers.title.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.title.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.title.selectedColor};
  }
`

export const MenuItemSizeButtonSubtitle = styled(Body)`
  margin: 0.4rem 0 0;
  transition: ${(props) => props.theme.modifiers.box.transition};
  font-size: ${(props) => props.theme.modifiers.text.fontSize};
  color: ${(props) => props.theme.modifiers.text.color};

  button.show-hover:not(.selected):hover & {
    color: ${(props) => props.theme.modifiers.text.hoverColor};
  }

  button.selected & {
    color: ${(props) => props.theme.modifiers.text.selectedColor};
  }
`

export const MenuItemSizeButtonPrice = styled.span`
  margin: 0.5rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0;
  }
`
