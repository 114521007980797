import styled from '@emotion/styled'
import { X } from '../icons'
import Body from '../Body'
import { Coverage } from './types'
import { CSSProperties } from 'react'

const alignSelf: Record<Coverage, CSSProperties['alignSelf']> = {
  '1st-half': 'flex-start',
  whole: 'center',
  '2nd-half': 'flex-end',
}

const width: Record<Coverage, CSSProperties['width']> = {
  '1st-half': '50%',
  whole: '100%',
  '2nd-half': '50%',
}

export const PizzaBuilderOptionView = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.7rem 0.8rem;
  border-radius: ${(props) => props.theme.buttons.sizes.small.borderRadius};
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.bgColors.primary};

  &:hover {
    color: ${(props) => props.theme.colors.error};
  }
`

export const PizzaBuilderOptionClose = styled(X)``

export const PizzaBuilderOptionTitle = styled(Body)`
  color: inherit;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  margin-left: 6px;
  margin-right: 12px;
  white-space: nowrap;
`

export const PizzaBuilderOptionCoverageView = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  outline: 1px solid ${(props) => props.theme.bgColors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const PizzaBuilderOptionCoverage = styled.div<{ coverage: Coverage }>`
  height: 100%;
  background-color: ${(props) => props.theme.colors.alert};
  align-self: ${(props) => alignSelf[props.coverage]};
  width: ${(props) => width[props.coverage]};
`
