import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  selectBrand,
  selectContent,
  selectCustomer,
  selectCustomerOrder,
  selectConfirmationOrder,
  selectOptIns,
  resetCheckout,
  resetCompletedOrder,
  resetConfirmation,
  resetGroupOrder,
  resetOrder,
  resetOrderFulfillment,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { makeConfirmationOrderType } from '@open-tender/utils'
import { Button, Heading, OrderFulfillmentForm } from 'components'
import {
  Content,
  Main,
  Order,
  PageTitle,
  PageContent,
  HeaderDefault,
  PageContainer,
} from '../..'
import ConfirmationPrefs from './ConfirmationPrefs'
import ConfirmationLinks from './ConfirmationLinks'
import styled from '@emotion/styled'

const ConfirmationFooter = styled.div`
  display: flex;
  justify-content: center;
`

export const ConfirmationMessage = styled(Heading)`
  margin: 3rem 0;
  font-size: ${(props) => props.theme.fonts.sizes.big};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 2rem 0;
    font-size: ${(props) => props.theme.fonts.sizesMobile.big};
  }
`

const Confirmation = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showPrefs, setShowPrefs] = useState(false)
  const { confirmation: config } = useAppSelector(selectContent) || {}
  const brand = useAppSelector(selectBrand)
  const order = useAppSelector(selectConfirmationOrder)
  const { order: customerOrder } = useAppSelector(selectCustomerOrder)
  const orderCurrent =
    customerOrder && customerOrder.order_id === order?.order_id
      ? customerOrder
      : order
  const { revenue_center, service_type } = orderCurrent || {}
  const { auth, profile } = useAppSelector(selectCustomer)
  const isNew = auth && profile && !profile.is_notification_set
  const optIns = useAppSelector(selectOptIns)
  const { accepts_marketing, order_notifications } = optIns
  const showOptIns = isNew && (accepts_marketing || order_notifications)
  const hasExtCurbside = brand?.has_ext_curbside === true
  const hasFulfillment =
    brand?.fulfillment &&
    revenue_center?.has_order_fulfillment &&
    service_type === 'PICKUP' &&
    !hasExtCurbside
  const confirmationOrderType = order ? makeConfirmationOrderType(order) : null
  const section = confirmationOrderType
    ? config?.sections[confirmationOrderType]
    : null

  useEffect(() => {
    dispatch(resetCompletedOrder())
    dispatch(resetOrder())
    dispatch(resetCheckout())
    dispatch(resetGroupOrder())
    return () => {
      dispatch(resetConfirmation())
    }
  }, [dispatch])

  useEffect(() => {
    if (!order) navigate('/account')
  }, [order, navigate])

  useEffect(() => {
    if (!hasFulfillment) dispatch(resetOrderFulfillment())
  }, [hasFulfillment, dispatch])

  useEffect(() => {
    if (showOptIns) setShowPrefs(true)
  }, [showOptIns])

  return (
    <>
      <Helmet>
        <title>Confirmation | {brand?.title}</title>
      </Helmet>
      <Content>
        <HeaderDefault showOrderNow={false} />
        <Main>
          <PageContainer>
            <PageTitle {...config}>
              {!!section?.message && (
                <ConfirmationMessage as="p">
                  {section.message}
                </ConfirmationMessage>
              )}
              <ConfirmationLinks auth={auth} brand={brand} />
            </PageTitle>
            {customerOrder?.order_id && hasFulfillment && (
              <OrderFulfillmentForm
                orderId={customerOrder?.order_id}
                settings={brand.fulfillment}
              />
            )}
            <PageContent style={{ margin: '2.5rem auto' }}>
              {showPrefs && <ConfirmationPrefs />}
            </PageContent>
            {orderCurrent && (
              <Order order={orderCurrent} isConfirmation={true} />
            )}
            <ConfirmationFooter>
              <Button onClick={() => navigate(`/account`)}>Back Home</Button>
            </ConfirmationFooter>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Confirmation
