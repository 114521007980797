import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  selectContentSection,
  selectDisplaySettings,
  selectGroupOrder,
  selectMenuSlug,
  selectOrder,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Allergens,
  Back,
  Cart,
  GroupOrder,
  GroupOrderIcon,
  LeaveGroup,
  LeaveGroupIcon,
  NavMenu,
  Tags,
} from 'components/buttons'
import { Header, HeaderLogoDevice } from 'components'
import MenuMobileMenu from '../MenuMobileMenu'
import MenuHeaderOrder from './MenuHeaderOrder'
import { MenuHeaderGroupOrder, MenuHeaderItem } from './MenuHeader.styled'

const MenuHeader = ({ back }: { back?: React.ReactNode }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const { allergens: displayAllergens, tags: displayTags } =
    useAppSelector(selectDisplaySettings) || {}
  const order = useAppSelector(selectOrder)
  const { revenueCenter } = order
  const showGroupOrdering = revenueCenter
    ? !!revenueCenter.group_ordering
    : false
  const { cartGuest } = useAppSelector(selectGroupOrder)
  const showAllergens = displayAllergens ? true : false
  const showTags = displayTags ? true : false
  const menuSlug = useAppSelector(selectMenuSlug)
  const { displayLogo = true, displayLogoMobile = true } =
    useAppSelector(selectContentSection('menu')) || {}
  const showLogo = isMobile ? displayLogoMobile : displayLogo
  const groupOrderDesktop = cartGuest ? <LeaveGroup /> : <GroupOrder />
  const groupOrderMobile = cartGuest ? <LeaveGroupIcon /> : <GroupOrderIcon />
  const groupOrderButton = isMobile ? groupOrderMobile : groupOrderDesktop

  return (
    <>
      <Header
        style={{ boxShadow: 'none' }}
        title={showLogo ? <HeaderLogoDevice /> : null}
        left={
          <>
            {back || <Back label="Back to Menu" path={menuSlug} />}
            <MenuHeaderOrder
              cartGuest={cartGuest}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              showLogo={showLogo}
            />
          </>
        }
        right={
          <>
            {showGroupOrdering ? (
              <MenuHeaderGroupOrder>{groupOrderButton}</MenuHeaderGroupOrder>
            ) : null}
            {showTags && (
              <MenuHeaderItem>
                <Tags />
              </MenuHeaderItem>
            )}
            {showAllergens && (
              <MenuHeaderItem>
                <Allergens />
              </MenuHeaderItem>
            )}
            <Cart />
            <NavMenu />
          </>
        }
      />
      <MenuMobileMenu showMenu={showMenu} setShowMenu={setShowMenu} />
    </>
  )
}

export default MenuHeader
