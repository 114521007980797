import styled from '@emotion/styled'

export const MenuItemWrapperView = styled.div`
  label: MenuItemWrapperView;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

export const MenuItemWrapperImage = styled.div`
  label: MenuItemWrapperImage;
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  background-color: ${(props) => props.theme.bgColors.tertiary};
  padding: ${(props) => props.theme.item.desktop.imagePaddingAll};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`

export const MenuItemWrapperImagePlaceholder = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 50%;
    width: auto;
  }
`

export const MenuItemWrapperContent = styled.div`
  label: MenuItemWrapperContent;
  flex: 0 0 ${(props) => props.theme.item.desktop.maxWidth};
  width: ${(props) => props.theme.item.desktop.maxWidth};
  height: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.bgColors.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    flex: 1 1 auto;
  }
`
