import styled from '@emotion/styled'
import {
  CartItem,
  CartItemWithPoints,
  CheckoutPoints,
  DisplayItem,
} from '@open-tender/types'
import { formatDollars, makeModifierNames } from '@open-tender/utils'
import { BgImage, CartItemCount } from 'components'
import Body from 'components/Body'
import Heading from 'components/Heading'
import CartSummaryItemPoints from './CartSummaryItemPoints'

const CartSummaryItemView = styled.div`
  margin: 0 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CartSummaryItemImage = styled(BgImage)`
  position: relative;
  width: 6rem;
  min-width: 6rem;
  height: 6rem;
  margin: 0 2rem 0 0;
  border-radius: ${(props) => props.theme.border.radiusSmall};
  // background-color: ${(props) => props.theme.bgColors.tertiary};
  background-color: rgba(0, 0, 0, 0.05);
`

const CartSummaryItemContainer = styled.div`
  flex-grow: 1;
`

const CartSummaryItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const CartSummaryItemContent = styled.div`
  flex: 1 1 100%;
`

const CartSummaryItemName = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.small};
`

const CartSummaryItemMods = styled(Body)`
  margin: 0.3rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
`

const CartSummaryItemMadeFor = styled(Heading)`
  margin: 0.4rem 0 0;
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
`

const CartSummaryItemPrice = styled.div`
  flex: 1 0 10rem;
  text-align: right;

  p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    font-weight: ${(props) => props.theme.boldWeight};
    color: ${(props) => props.theme.colors.primary};
  }
`

const CartSummaryItem = ({
  item,
  pointsObj,
  children,
}: {
  item: CartItemWithPoints | DisplayItem | CartItem
  pointsObj: CheckoutPoints | null
  children?: React.ReactNode
}) => {
  const bgStyle = item.imageUrl
    ? { backgroundImage: `url(${item.imageUrl}` }
    : undefined
  const price = formatDollars(item.totalPrice?.toString() || '0.00')
  const mods = makeModifierNames(item)
  const hasPoints = item.points && pointsObj

  return (
    <CartSummaryItemView>
      <CartSummaryItemImage style={bgStyle}>
        <CartItemCount count={item.quantity} />
      </CartSummaryItemImage>
      <CartSummaryItemContainer>
        <CartSummaryItemInfo>
          <CartSummaryItemContent>
            <CartSummaryItemName as="p">{item.name}</CartSummaryItemName>
            {mods && <CartSummaryItemMods as="p">{mods}</CartSummaryItemMods>}
            {item.madeFor && (
              <CartSummaryItemMadeFor as="p">
                For <span>{item.madeFor}</span>
              </CartSummaryItemMadeFor>
            )}
            {item.notes && (
              <CartSummaryItemMadeFor as="p">
                Notes: <span>{item.notes}</span>
              </CartSummaryItemMadeFor>
            )}
          </CartSummaryItemContent>
          <CartSummaryItemPrice>
            <p>{price}</p>
            {hasPoints ? (
              <CartSummaryItemPoints
                item={item as CartItemWithPoints}
                pointsObj={pointsObj}
              />
            ) : (
              children
            )}
          </CartSummaryItemPrice>
        </CartSummaryItemInfo>
      </CartSummaryItemContainer>
    </CartSummaryItemView>
  )
}

export default CartSummaryItem
