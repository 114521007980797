import { Button } from 'components'

import styled from '@emotion/styled'
import { Box } from 'components'

export const LandingOrderTypeView = styled('div')`
  width: 25%;
  max-width: 40rem;
  padding: 1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.narrow}) {
    width: 50%;
  }
  @media (max-width: 1160px) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 1.5rem 0;
  }
`

export const LandingOrderTypeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 2rem;
  height: 100%;

  button {
    display: block;
    flex-grow: 0;
    width: 24rem;
    max-width: 100%;
    margin: 2rem auto 2rem;
  }
`

export const LandingOrderTypeHeader = styled('div')`
  flex-grow: 1;

  h3,
  p {
    display: block;
    text-align: center;
    width: 100%;
  }

  h3 {
    // font-size: ${(props) => props.theme.fonts.sizes.h3};
    line-height: 1;
  }

  p {
    margin: 0.5rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }

  div p {
    margin: 1rem 0 0;
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  }
`
export const LandingOrderTypePost = ({
  title,
  subtitle,
  excerpt,
  content,
  files,
  onClick,
}: {
  title: string
  subtitle: string
  excerpt: string
  content: string
  files: unknown[]
  onClick: () => void
}) => {
  // const image = files.find((i) => i.type === 'FEATURED_IMAGE')
  return (
    <LandingOrderTypeView>
      <LandingOrderTypeContainer>
        <LandingOrderTypeHeader>
          <h3>{title}</h3>
          <Button onClick={onClick}>{subtitle}</Button>
          <p>{excerpt}</p>
          {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
        </LandingOrderTypeHeader>
      </LandingOrderTypeContainer>
    </LandingOrderTypeView>
  )
}
