import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import {
  CheckoutFormCustomer,
  CustomerCreate,
  FormFieldType,
} from '@open-tender/types'
import { useSignUpGuestForm } from '@open-tender/utils'
import {
  resetSignUp,
  selectCheckout,
  selectBrand,
  selectGuest,
  selectSignUp,
  signUpCustomer,
  updateForm,
  useAppSelector,
  useAppDispatch,
  resetCheckout,
  validateOrder,
  selectContent,
} from '@open-tender/cloud'
import {
  Body,
  ButtonLink,
  ButtonSubmit,
  Checkbox,
  FormError,
  FormInputs,
  Text,
  ThirdPartyLoyaltyTerms,
} from 'components'

const SignUpGuestFormNote = styled.div`
  width: 100%;
  height: 2rem;
  margin: -1rem 0 0;
  display: flex;
  align-items: center;

  & > span {
    display: block;
  }
`

const SignUpGuestFormTerms = styled.div`
  margin: 0 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: -1.5rem 0 ${(props) => props.theme.layout.padding};
  }

  div {
    margin: 0;
  }
`

const SignUpGuestFormSubmit = styled.div`
  margin: 1.5rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: block;
  }

  button + button {
    margin: 0 0 0 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`

const SignUpGuestFormCommunications = styled.div`
  margin: -1.5rem 0 3rem;

  label {
    margin: 0 0 1.5rem;
  }
`

const SignUpGuestForm = ({ showGuest = true }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const {
    has_thanx,
    tpls,
    notification_preferences: prefs,
  } = useAppSelector(selectBrand) || {}
  const notificationChannels = prefs
    ? Array.from(new Set(prefs?.map((i) => i.notification_channel)))
    : []
  const { form, check, errors: errs } = useAppSelector(selectCheckout)
  const { email } = useAppSelector(selectGuest)
  const { loading, error } = useAppSelector(selectSignUp)
  const { signUp: config } = useAppSelector(selectContent) || {}
  const guestErrors = (errs.customer as Record<string, string>) || null
  const hasGuestErrors = guestErrors ? true : false
  const { display_marketing_message, marketing } = config || {}
  const commsMessage = display_marketing_message
    ? marketing || 'Choose your communication preferences:'
    : null

  const signUp = useCallback(
    (data: CustomerCreate) => dispatch(signUpCustomer({ data })),
    [dispatch]
  )

  const submitGuest = useCallback(
    async (data: CheckoutFormCustomer) => {
      setSubmitted(true)
      dispatch(resetSignUp())
      await dispatch(updateForm({ customer: data }))
      dispatch(validateOrder())
    },
    [dispatch]
  )

  useEffect(() => {
    if (submitted && check && !hasGuestErrors) {
      navigate('/checkout')
    }
  }, [submitted, check, hasGuestErrors, navigate])

  useEffect(() => {
    if (hasGuestErrors) {
      setSubmitted(false)
      dispatch(resetCheckout())
    }
  }, [dispatch, hasGuestErrors])

  useEffect(() => {
    dispatch(resetSignUp())
    return () => {
      dispatch(resetSignUp())
    }
  }, [dispatch])

  const {
    submitRef,
    fields,
    notificationFields,
    data,
    comms,
    disabled,
    guestDisabled,
    errors,
    submitting,
    handleChange,
    handleSubmit,
    handleGuest,
    handleComms,
  } = useSignUpGuestForm(
    email || '',
    form.customer,
    loading,
    error,
    guestErrors,
    signUp,
    submitGuest,
    tpls,
    notificationChannels
  )
  const passwordNeeded = !guestDisabled && !data.password && !has_thanx
  const hasThirdPartyTerms = !!tpls && tpls !== 'SPARKFLY'

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  return (
    <form id="signup-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <SignUpGuestFormNote>
        {showGuest && passwordNeeded ? (
          <Text color="alert" size="xSmall">
            Enter a password to create an account or proceed as a guest
          </Text>
        ) : null}
      </SignUpGuestFormNote>
      {data.password && (
        <>
          {hasThirdPartyTerms ? (
            <SignUpGuestFormTerms>
              <ThirdPartyLoyaltyTerms includeMarketing={false} />
            </SignUpGuestFormTerms>
          ) : (
            <SignUpGuestFormCommunications>
              {commsMessage && (
                <Body as="p" size="small" style={{ marginBottom: 15 }}>
                  {commsMessage}
                </Body>
              )}
              {notificationFields.map((field) => {
                const key = field.name.toLowerCase() as keyof typeof config
                const label = config && config[key]
                return (
                  <Checkbox
                    key={field.name}
                    type={field.type}
                    label={label || field.label}
                    name={field.name}
                    on={!!comms.includes(field.name)}
                    onChange={(evt) =>
                      handleComms(field.name, evt.currentTarget.checked)
                    }
                  />
                )
              })}
            </SignUpGuestFormCommunications>
          )}
        </>
      )}
      <SignUpGuestFormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={disabled || submitting}>
          {submitting ? 'Submitting...' : 'Sign Up'}
        </ButtonSubmit>
        {showGuest ? (
          <ButtonLink
            onClick={handleGuest}
            disabled={guestDisabled || submitting}
          >
            Checkout as a guest
          </ButtonLink>
        ) : null}
      </SignUpGuestFormSubmit>
    </form>
  )
}

export default SignUpGuestForm
