import { useNavigate } from 'react-router'
import { selectCheckout, useAppSelector } from '@open-tender/cloud'
import { ButtonLink, Loading } from 'components'
import CheckoutSection from './CheckoutSection'
import CheckoutSectionFootnote from './CheckoutSectionFootnote'
import CheckoutSectionTitle from './CheckoutSectionTitle'

const CheckoutGuest = () => {
  const navigate = useNavigate()
  const { form, check, loading } = useAppSelector(selectCheckout)
  const { first_name, last_name, email, phone } = form.customer
  const name = first_name && last_name ? `${first_name} ${last_name}` : null

  const updateInfo = () => {
    navigate(email ? '/checkout/signup' : '/checkout/guest')
  }

  return check && name ? (
    <CheckoutSection title="Contact Info">
      <CheckoutSectionTitle>{name}</CheckoutSectionTitle>
      {email ? <p>{email}</p> : <></>}
      {phone ? <p>{phone}</p> : <></>}
      <CheckoutSectionFootnote>
        <p>
          <ButtonLink onClick={updateInfo}>Update your contact info</ButtonLink>
        </p>
      </CheckoutSectionFootnote>
    </CheckoutSection>
  ) : loading === 'pending' ? (
    <CheckoutSection>
      <Loading text="Loading..." style={{ textAlign: 'left' }} />
    </CheckoutSection>
  ) : null
}

export default CheckoutGuest
